import axios from 'axios'

import * as types from '../actions/ActionTypes'
import process from '../environment.json'
import { getNowTimestamp } from '../external/utilities/DateUtils'
import { forwardToError, forwardToNotLoggedIn } from '../router/RouteManager'
import BackendCache from './BackendCache'

// import { pushState } from 'redux-react-router';

export const pre = '/data'
export const prodEnv = 'prod'
export const root = `https://${process.env.API_URL}/jaxrs-json`
export const rootChub = `https://${process.env.CHUB_API}`
export const rootSWEnv = process.env.ENVIRONMENT
export const rootSWPartnerId = process.env.SW_PARTNER_ID
export const rootSWProductId = process.env.SW_PRODUCT_ID

// const apiVersion = 'application/vnd.active.common-v1+json'
const apiVersion = 'application/json'

/*
██████╗  ██████╗     ███╗   ██╗ ██████╗ ████████╗    ████████╗ ██████╗ ██╗   ██╗ ██████╗██╗  ██╗
██╔══██╗██╔═══██╗    ████╗  ██║██╔═══██╗╚══██╔══╝    ╚══██╔══╝██╔═══██╗██║   ██║██╔════╝██║  ██║
██║  ██║██║   ██║    ██╔██╗ ██║██║   ██║   ██║          ██║   ██║   ██║██║   ██║██║     ███████║
██║  ██║██║   ██║    ██║╚██╗██║██║   ██║   ██║          ██║   ██║   ██║██║   ██║██║     ██╔══██║
██████╔╝╚██████╔╝    ██║ ╚████║╚██████╔╝   ██║          ██║   ╚██████╔╝╚██████╔╝╚██████╗██║  ██║
╚═════╝  ╚═════╝     ╚═╝  ╚═══╝ ╚═════╝    ╚═╝          ╚═╝    ╚═════╝  ╚═════╝  ╚═════╝╚═╝  ╚═╝
*/

export function requestData(fwdAction = null) {
  return {
    type: types.REQUEST_DATA,
    data: { fwdAction },
  }
}

export function receiveData(dataIn, fwdAction) {
  return {
    type: fwdAction,
    data: dataIn,
  }
  // dispatch({type: types.RECV_DATA, data: json});
}

export function receiveError(dataIn, failSilently) {
  return {
    type: types.RECEIVE_ERROR,
    data: dataIn,
    failSilently
  }
}

export function isLoginRequiredStatus(status) {
  if (status === 401 || status === 403) {
    return true
  }

  return false
}

export function sendToError(response) {
  console.error(response)

  if (isLoginRequiredStatus(response.status)) {
    forwardToNotLoggedIn()
  } else {
    forwardToError()
  }
}

export function getNoCacheParam() {
  return { 'no-cache': getNowTimestamp() }
}

export function getAxiosConfig(
  verb,
  url,
  queryParamsObj,
  bodyParamsObj,
  humanVerificationToken = '',
  humanVerificationTokenV3 = '',
  humanVerificationActionV3 = '',
) {
  if (!queryParamsObj) {
    queryParamsObj = {}
  }

  if (!bodyParamsObj) {
    bodyParamsObj = {}
  }

  const axiosConfig = {
    url,
    timeout: 0,
    method: verb,
    params: queryParamsObj,
    data: bodyParamsObj,
    headers: {
      Accept: apiVersion,
      humanVerificationToken,
      humanVerificationTokenV3,
      humanVerificationActionV3,
      'Access-Control-Allow-Origin': process.env.CORS_ORIGIN,
      'Access-Control-Allow-Methods':
        'DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT',
      'Access-Control-Allow-Headers':
        'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,humanVerificationToken,humanVerificationTokenV3,humanVerificationActionV3,a1Data,privateKey,access-control-allow-methods,X-Requested-With,Access-Control-Allow-Origin,Accept,Origin,Access-Control-Allow-Headers,Access-Control-Request-Headers',
    },
  }

  return axiosConfig
}

export function getLowAxiosConfig(
  verb,
  url,
  queryParamsObj,
  bodyParamsObj,
  humanVerificationToken = '',
  humanVerificationTokenV3 = '',
  humanVerificationActionV3 = '',
) {
  if (!queryParamsObj) {
    queryParamsObj = {}
  }

  if (!bodyParamsObj) {
    bodyParamsObj = {}
  }

  const axiosConfig = {
    url,
    timeout: 0,
    method: verb,
    params: queryParamsObj,
    data: bodyParamsObj,
    headers: {
      Accept: apiVersion,
      humanVerificationToken,
      humanVerificationTokenV3,
      humanVerificationActionV3,
    },
  }

  return axiosConfig
}

export function getConfigWithCache(
  verb,
  url,
  queryParamsObj = null,
  bodyParamsObj = null,
  shouldCache = false,
  ttl = 0,
) {
  const axiosConfig = getAxiosConfig(verb, url, queryParamsObj, bodyParamsObj)
  const cacheConfig = {
    shouldCache,
    ttl,
  }

  return {
    axiosConfig,
    cacheConfig,
  }
}

export function fetchBackendData(
  verb,
  actionType,
  url,
  isResolving = false,
  queryParamsObj,
  bodyParamsObj,
) {
  const axiosConfig = getAxiosConfig(verb, url, queryParamsObj, bodyParamsObj)

  return function (dispatch) {
    dispatch(requestData(actionType))

    return axios(axiosConfig)
      .then((response) => {
        dispatch(receiveData(response.data, actionType))
      })
      .catch((response) => {
        dispatch(receiveError(response.data))
        if (isResolving) {
          sendToError(response)
          // pushRoute(ROUTE_ERROR)
        }
      })
  }
}

export function batchFetchBackendData(
  txns,
  fwdAction,
  isResolving = false,
  successfulCallback,
  failSilently = false,
) {
  return function (dispatch) {
    dispatch(requestData(fwdAction))

    return axios
      .all(txns)
      .then((arr) => {
        const dataArr = []
        for (let i = 0; i < arr.length; i++) {
          dataArr.push(arr[i].data)
        }
        if (successfulCallback) {
          successfulCallback(dataArr, fwdAction)
        }
        dispatch(receiveData(dataArr, fwdAction))
      })
      .catch(({ response }) => {
        dispatch(receiveError(response?.data, failSilently))

        if (isResolving) {
          sendToError(response)
          // pushRoute(ROUTE_ERROR)
        }
      })
  }
}

export function batchFetchBackendDataWithCache(
  configs,
  fwdAction,
  isResolving = false,
) {
  return function (dispatch) {
    dispatch(requestData(fwdAction))

    const backendCache = BackendCache.get()
    const responses = []
    const txns = []

    for (const config of configs) {
      const { axiosConfig, cacheConfig } = config

      let cachedResponse = null

      if (cacheConfig.shouldCache) {
        cachedResponse = backendCache.getData(axiosConfig)
      }

      if (axiosConfig.method === 'get' && cachedResponse !== null) {
        responses.push(cachedResponse)
      } else {
        responses.push(null)
        txns.push(axios(axiosConfig))
      }
    }

    return axios
      .all(txns)
      .then((arr) => {
        let dataCtr = 0
        for (let i = 0; i < responses.length; i++) {
          if (responses[i] === null) {
            responses[i] = arr[dataCtr].data
            const config = configs[i]
            if (config.cacheConfig.shouldCache) {
              backendCache.addData(
                config.axiosConfig,
                arr[dataCtr].data,
                config.cacheConfig.ttl,
              )
            }
            dataCtr += 1
          }
        }
        dispatch(receiveData(responses, fwdAction))
      })
      .catch((response) => {
        dispatch(receiveError(response.data))
        if (isResolving) {
          sendToError(response)
        }
      })
  }
}

export function executeTxnsWithCallback(
  txns,
  fwdAction = null,
  initTxnCompleteCallback = null,
  isResolving = false,
) {
  return function (dispatch) {
    const allData = []
    dispatch(requestData(fwdAction))

    return axios
      .all(txns)
      .then((arr1) => {
        for (let i = 0; i < arr1.length; i++) {
          allData.push(arr1[i].data)
        }

        return axios
          .all(initTxnCompleteCallback(allData))
          .then((arr2) => {
            for (let i = 0; i < arr2.length; i++) {
              allData.push(arr2[i].data)
            }
            dispatch(receiveData(allData, fwdAction))
          })
          .catch((response) => {
            dispatch(receiveError(response.data))
            if (isResolving) {
              sendToError(response)
              // pushRoute(ROUTE_ERROR)
            }
          })
      })
      .catch((response) => {
        dispatch(receiveError(response.data))
        if (isResolving) {
          sendToError(response)
          // pushRoute(ROUTE_ERROR)
        }
      })
  }
}

export function fetchBackendDataInline(
  verb,
  url,
  queryParamsObj,
  bodyParamsObj,
  callback,
) {
  const axiosConfig = getAxiosConfig(verb, url, queryParamsObj, bodyParamsObj)

  axios(axiosConfig)
    .then((response) => {
      const { response: axiosResponse } = response;

      callback(true, axiosResponse?.data || response?.data)
    })
    .catch((response) => {
      const { response: axiosResponse } = response;

      callback(false, axiosResponse?.data || response?.data)
    })
}

export function fetchBackendDataInlineWithCache(config, callback) {
  const backendCache = BackendCache.get()

  const { axiosConfig, cacheConfig } = config

  let cachedResponse = null

  if (cacheConfig.shouldCache) {
    cachedResponse = backendCache.getData(axiosConfig)
  }

  if (axiosConfig.method === 'get' && cachedResponse !== null) {
    return callback(true, cachedResponse)
  }
  axios(axiosConfig)
    .then((response) => {
      // console.log('fetchBackendDataInline success: ' + response)
      if (cacheConfig.shouldCache) {
        backendCache.addData(axiosConfig, response.data, config.cacheConfig.ttl)
      }
      callback(true, response.data)
    })
    .catch((response) => {
      // console.log('fetchBackendDataInline fail: ' + response)
      callback(false, response.data)
    })
}

export function shortCircuitCache() {
  const backendCache = BackendCache.get()
  backendCache.clear()
}

export function getErrorMessage(err) {
  return (
    err?.response?.data?.errors?.[0]?.message ??
    err?.response?.data?.error_message ??
    err?.data?.errorMessage ??
    err?.data?.message ??
    err?.data?.errors?.[0]?.message ??
    err?.message
  )
}

export function getErrorMessageToUser(err) {
  return (
    err?.data?.[0]?.api_response?.faults?.[0]?.defaultMessage ??
    err?.data?.[0]?.api_response?.faults?.[0]?.messageTemplate
  )
}

export function getErrorMessages(err) {
  const messages = []

  if (err?.response?.data?.errors.length) {
    err.response.data.errors.forEach((error) => {
      messages.push(error.message)
    })
  }

  if (err?.data?.errors?.length) {
    err.data.errors.forEach((error) => {
      messages.push(error.message)
    })
  }

  if (!messages.length) return [getErrorMessage(err)]

  return messages
}
