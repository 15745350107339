import PropTypes from 'prop-types'
import React from 'react'
import Col from 'react-bootstrap/Col'

/** Wrapper class for react-bootstrap column.  Normally preceeded by a row element https://react-bootstrap.github.io/components.html#grid-props-col

@example ../../../../docs/external/layout/ACol.md */

const ACol = ({
  bsPrefix,
  as,
  xl,
  lg,
  md,
  sm,
  xs,
  lgHidden,
  mdHidden,
  smHidden,
  xsHidden,
  children,
  style = '',
}) => {
  const getHiddenClassName = () => {
    let className = ''

    if (xsHidden) className += ' hidden-xs'

    if (smHidden) className += ' hidden-sm'

    if (mdHidden) className += ' hidden-md'

    if (lgHidden) className += ' hidden-lg'

    return className
  }

  return (
    <Col
      className={`${style} ${getHiddenClassName()}`}
      bsPrefix={bsPrefix}
      as={as}
      xl={xl}
      lg={lg}
      md={md}
      sm={sm}
      xs={xs}
    >
      {children}
    </Col>
  )
}

ACol.propTypes = {
  bsPrefix: PropTypes.string,
  as: PropTypes.string,
  lg: PropTypes.number,
  md: PropTypes.number,
  xs: PropTypes.number,
  sm: PropTypes.number,

  lgHidden: PropTypes.bool,
  mdHidden: PropTypes.bool,
  smHidden: PropTypes.bool,
  xsHidden: PropTypes.bool,

  children: PropTypes.node,
  style: PropTypes.string,
}

export default ACol
