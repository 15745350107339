import { any, arrayOf, bool, func, number, object, string } from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import root from 'window-or-global'

import { changeTheme, getLocalState } from '../actions/Application'
import {
  chubToggleCart,
  chubToggleCartModal,
  getLocale,
  requestFeedback,
  sendFeedback,
  setRootInfo,
} from '../actions/Backend'
import {
  FOOTER_NOT_VISIBLE_ROUTES,
  HEADER_NOT_VISIBLE_ROUTES,
  HEADER_TRACE_ID_VISIBLE_ROUTES,
  ROUTE_BASE,
  ROUTE_ROOT,
  ROUTE_WELCOME,
  SEARCH_NOT_VISIBLE_ROUTES,
} from '../constants/Routes'
import AClearfix from '../external/components/layout/AClearfix'
import Cont from '../external/components/layout/Cont'
import MetaData from '../external/components/meta/MetaData'
import SRLoadAnnouncer from '../external/components/misc/SRLoadAnnouncer'
import { MENU_OPEN } from '../external/components/navigation/rolloutMenu/RolloutMenu'
import AAlert from '../external/components/overlay/AAlert'
import Spinner from '../external/components/overlay/Spinner'
import AModal from '../external/components/overlay/modal/AModal'
import AModalBody from '../external/components/overlay/modal/AModalBody'
import AModalHeader from '../external/components/overlay/modal/AModalHeader'
import SimpleModal from '../external/components/overlay/modal/SimpleModal'
import { isEnvServer } from '../external/utilities/GeneralServerUtils'
import {
  findObjectInArray,
  hackIsMobile,
  isEmpty,
} from '../external/utilities/GeneralUtils'
import { LANDMARKS } from '../external/utilities/accessibility/Definitions'
import { forwardToSignOut } from '../router/ExtRouteManager'
import {
  PROTOCOL,
  forwardToSearchResults,
  getCurrentPageRoute,
  isVisibleForRoute,
  openSpecialProtocolPath,
  replaceSignInTrackingParams,
  sendToExternalPath,
} from '../router/RouteManager'
import { selectCartMixpanelPayload } from '../selectors/cart'
import braze from '../thirdParty/braze'
import mixpanel from '../thirdParty/mixpanel'
import { getItemCount, getTimeToExpiry } from '../utilities/CartUtils'
import { getAdConfig } from '../utilities/ConfigUtils'
import { FEEDBACK_GROUPS } from '../utilities/FeedbackUtils'
import {
  getWebChatAgentStatus,
  getWebChatStatus,
  isWebChatAgentAvailable,
  startWebChat,
} from '../utilities/HackChatUtils'
import { getLiteralValuesForApp } from '../utilities/MetaDataUtils'
import { getMainSearchObj, mergeQueries } from '../utilities/QueryParamUtils'
import {
  getAllQuickSearchResults,
  getAllSearchProviderSourceAttribs,
  getSearchObj,
  isCurrSearchSelected,
  onSearchResultItemSelectRequestUtil,
  onSearchTermChangeUtil,
  onSearchTriggerUtil,
} from '../utilities/SearchUtils'
import { getPath, getPathname } from '../utilities/ServerUtils'
import Translation, { simpleTranslate, translate } from './Translation'
import { chubRemoveCart } from './cart/checkout/actions'
import { cartMixpanelPayloadShape } from './cart/shapes'
import { FeedbackSurvey } from './feedback'
import ContactTrigger from './feedback/ContactTrigger'
import MainFooter from './shared/footer/MainFooter'
import Header from './shared/header/Header'

export class KeyboardShortcuts extends Component {
  constructor(props) {
    super(props)

    this.state = { previousKey: null }
  }

  componentDidMount() {
    if (window.location.hostname !== 'reserveamerica.com') {
      window.addEventListener('keydown', this.onKeyDown.bind(this))
    }
  }

  onKeyDown({ key }) {
    if (!this.state.previousKey) {
      this.setState({ previousKey: key })
    } else if (this.state.previousKey === 'Shift') {
      switch (key) {
        case '!':
          this.props.onToggleCart()
          break
        case '@':
          this.props.onToggleSW()
          break
        case '#':
          this.props.onToggleVouchers()
          break
        default:
      }
    }
  }

  render() {
    return <div></div>
  }

  static propTypes = {
    onToggleCart: func,
    onToggleSW: func,
    onToggleVouchers: func,
  }
}

const PAGE_ID = 'PAGE_APP'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      feedbackModalOpen: false,
      signOutModalOpen: false,
      redirectModalOpen: false,
      smsModalOpen: false,
      requestFeedbackCtr: props.requestFeedbackCtr,
      redirectCtr: props.redirectCtr,
      webChatAgentStatus: '',
      webChatStatus: '',
      contactMenuOpen: false,
      isAbandoningS2nCart: false,
    }
    this.signInCtr = 0
  }

  componentDidMount() {
    const { location, userProfile } = this.props

    if (!isEnvServer()) {
      window.addEventListener('unload', this.onActiveCartLeave)
    }

    if (isEmpty(location.query) || isEnvServer()) return

    const { signInAcct, createAcct } = location.query

    const emailAddr = userProfile?.person?.emailAddress1 ?? ''
    const userID = userProfile?.userID ?? ''
    const address = userProfile?.person?.address ?? {}

    // Mixpanel tracking for sign in & create account
    if (createAcct === 'true') {
      mixpanel.successfulCreateAcct(emailAddr, address)
      braze.successfulCreateAcct(userID, emailAddr)
    }

    if (signInAcct === 'true') {
      mixpanel.successfulSignIn(emailAddr)
      braze.successfulSignIn(userID, emailAddr)
    }

    if (signInAcct || createAcct) {
      replaceSignInTrackingParams(location.pathname, location.search)
    }
  }

  onActiveCartLeave = () => {
    const { cartMixpanelPayload, cartItemCount } = this.props

    if (!cartItemCount) return

    mixpanel.leaveCart(cartMixpanelPayload)

    const name = cartMixpanelPayload.parkName.replaceAll(' ', '-').toLowerCase()

    const url = `https://www.reserveamerica.com/explore/${name}/${cartMixpanelPayload.contractCode}/${cartMixpanelPayload.parkId}/overview`

    const brazeEvent = { ...cartMixpanelPayload, url }
    braze.abandonCart(brazeEvent)
  }

  componentDidUpdate() {
    const { requestFeedbackCtr, redirectCtr } = this.props

    const newState = {}

    if (requestFeedbackCtr !== this.state.requestFeedbackCtr) {
      newState.feedbackModalOpen = true
      newState.requestFeedbackCtr = requestFeedbackCtr
    }

    if (redirectCtr !== this.state.redirectCtr) {
      newState.redirectCtr = redirectCtr
      newState.redirectModalOpen = true
    }

    if (!isEmpty(newState)) {
      this.setState(newState)
    }
  }

  getSignOutWarningModal() {
    const { intl } = this.props

    return (
      <SimpleModal
        show={this.state.signOutModalOpen}
        closeButton
        header={simpleTranslate(intl, 'labels_sign_out_warning_header', '')}
        body={
          <div>
            <Translation intl={intl} mkey="labels_sign_out_warning" />
          </div>
        }
        onPositiveClick={() => {
          this.onSignOutConfirmation()
        }}
        positiveLabel={simpleTranslate(intl, 'labels_generic_yes', '')}
        onNegativeClick={() => {
          this.onSignOutCancel()
        }}
        negativeLabel={simpleTranslate(intl, 'labels_generic_no', '')}
        onHide={this.onSignOutCancel.bind(this)}
      />
    )
  }

  onSignOutRequest() {
    const { cartItemCount } = this.props
    if (cartItemCount > 0) {
      this.setState({
        signOutModalOpen: true,
      })
    } else {
      forwardToSignOut()
    }
  }

  onSignOutConfirmation() {
    // if (window.navigation) {
    window.removeEventListener('unload', this.onActiveCartLeave)
    window.addEventListener('unload', () => {
      mixpanel.signOutWithCart(this.props.cartMixpanelPayload)
    })
    // } else {
    //   mixpanel.signOutWithCart(this.props.cartMixpanelPayload)

    //   await new Promise((res) => setTimeout(res, 100))
    // }
    // window.addEventListener('popstate', () => {
    // })

    forwardToSignOut()
  }

  onSignOutCancel() {
    this.setState({
      signOutModalOpen: false,
    })
  }

  getRedirectWarningModal() {
    const { intl, redirectConfig, cartItemCount } = this.props

    let headerText = ''
    let bodyText = ''
    if (!isEmpty(redirectConfig)) {
      headerText = simpleTranslate(intl, redirectConfig.promptHeaderToken)
      bodyText =
        cartItemCount > 0
          ? simpleTranslate(intl, redirectConfig.promptBodyTokenHasCartItems)
          : simpleTranslate(intl, redirectConfig.promptBodyToken)
    }

    return (
      <SimpleModal
        show={this.state.redirectModalOpen}
        closeButton
        header={headerText}
        body={<div>{bodyText}</div>}
        onPositiveClick={() => {
          this.onRedirectConfirmation()
        }}
        positiveLabel={simpleTranslate(intl, 'labels_generic_yes', '')}
        onNegativeClick={() => {
          this.onRedirectCancel()
        }}
        negativeLabel={simpleTranslate(intl, 'labels_generic_cancel', '')}
        onHide={this.onRedirectCancel.bind(this)}
      />
    )
  }

  onRedirectConfirmation() {
    const { redirectConfig } = this.props
    sendToExternalPath(redirectConfig.redirectURL, redirectConfig.newWindow)
    this.setState({
      redirectModalOpen: false,
    })
  }

  onRedirectCancel() {
    this.setState({
      redirectModalOpen: false,
    })
  }

  getSMSModal() {
    const { intl, brndConf } = this.props
    let contact = ''
    let headingText = ''
    let bodyText = ''
    if (!isEmpty(brndConf) && !isEmpty(brndConf.quiqImplContacts)) {
      contact = findObjectInArray(
        brndConf.quiqImplContacts,
        'type',
        'SMS',
      ).identifier
      headingText = simpleTranslate(intl, 'labels_contact-us_text_heading')
      bodyText = translate(intl, 'labels_contact-us_text_msg', { contact })
    }

    return (
      <SimpleModal
        show={this.state.smsModalOpen}
        closeButton
        header={headingText}
        body={<div>{bodyText}</div>}
        onHide={this.onSMSClose.bind(this)}
      />
    )
  }

  onSMSRequest() {
    const { brndConf } = this.props
    let contact = ''
    if (!isEmpty(brndConf)) {
      contact = findObjectInArray(brndConf.quiqImplContacts, 'type', 'SMS')
    }

    if (hackIsMobile()) {
      this.setState({
        contactMenuOpen: false,
      })
      openSpecialProtocolPath(PROTOCOL.sms, contact.identifier)
    } else {
      this.setState({
        smsModalOpen: true,
        contactMenuOpen: false,
      })
    }
  }

  onSMSClose() {
    this.setState({
      smsModalOpen: false,
    })
  }

  getToggleCartModal() {
    const { intl } = this.props

    return (
      <SimpleModal
        show={this.props.chubcartConfig.ui.show_toggle_cart_modal}
        closeButton
        header={simpleTranslate(intl, 'labels_cart_modal_title_warning', '')}
        body={
          <p>
            You cannot purchase Sensible Weather coverage when using a gift
            card, voucher or partial payment. However, you will be able to add
            Sensible Weather after purchase (see your confirmation email for
            details). Cancel to keep the current cart and the option to use
            Sensible Weather, or Continue to use a Gift Card, Voucher or partial
            payment
          </p>
        }
        onPositiveClick={() => {
          this.props.toggleCartModal()
          this.props.toggleCart({ chub_cart: false })
          this.props.clearChubCart()
        }}
        positiveLabel={simpleTranslate(
          intl,
          'myaccount_reservations_change_details',
          '',
        )}
        onNegativeClick={() => this.props.toggleCartModal()}
        negativeLabel={simpleTranslate(intl, 'labels_feedback_cancel', '')}
      />
    )
  }

  onWebChatRequest() {
    this.setState({
      contactMenuOpen: false,
    })
    if (isWebChatAgentAvailable(this.state.webChatAgentStatus)) {
      startWebChat()
    }
  }

  isAllowWebChat() {
    return false
  }

  onFeedbackRequest() {
    this.setState({
      contactMenuOpen: false,
    })
    this.props.onRequestFeedback()
  }

  closeFeedbackModal() {
    this.setState({
      feedbackModalOpen: false,
    })
  }

  onFeedbackSendRequest(feedbackGroup, feedback) {
    this.props.onFeedbackSendRequest(feedbackGroup, feedback)
  }

  onFeedbackCancelRequest() {
    this.closeFeedbackModal()
  }

  onContactTriggerMenuOpenClose(openState) {
    if (this.isAllowWebChat() && openState === MENU_OPEN) {
      this.setState({
        webChatAgentStatus: getWebChatAgentStatus(),
        webChatStatus: getWebChatStatus(),
      })
    }
  }

  render() {
    const {
      theme,
      intl,
      allQuickSearchResults,
      allQuickSearchResultsAttribs,
      mainSearchConfig,
      brndConf,
      onSearchTrigger,
      onSearchTermChange,
      onSearchResultItemSelectRequest,
      isLoading,
      showLoader,
      selectedSearchObj,
      cartTimeToExpiry,
      cartItemCount,
      currSearch,
      savedSearchFilter,
      homeConfig,
      mainConfig,
      feedbackConfig,
      addToFavoritesCtr,
      removeFromFavoritesCtr,
      launchURL,
      metaConfig,
    } = this.props
    const hasInlineSearch =
      getPathname(this.props, launchURL) !== getPath(ROUTE_BASE) &&
      getPathname(this.props, launchURL) !== getPath(ROUTE_ROOT, false) &&
      getPathname(this.props, launchURL) !== getPath(ROUTE_ROOT) &&
      getPathname(this.props, launchURL) !== getPath(ROUTE_WELCOME)
    const headerVisible = isVisibleForRoute(
      HEADER_NOT_VISIBLE_ROUTES,
      getPathname(this.props, launchURL),
    )
    const footerVisible = isVisibleForRoute(
      FOOTER_NOT_VISIBLE_ROUTES,
      getPathname(this.props, launchURL),
    )
    const searchVisible = isVisibleForRoute(
      SEARCH_NOT_VISIBLE_ROUTES,
      getPathname(this.props, launchURL),
    )
    const headerTraceIdNotVisible = isVisibleForRoute(
      HEADER_TRACE_ID_VISIBLE_ROUTES,
      getPathname(this.props, launchURL),
    )
    // const feedbackVisible = isVisibleForRoute(FEEDBACK_NOT_VISIBLE_ROUTES, getPathname(this.props, launchURL))
    const feedbackVisible = false

    let feedbackTrigger = null

    if (feedbackVisible) {
      feedbackTrigger = (
        <ContactTrigger
          theme={theme}
          intl={intl}
          onFeedbackRequest={this.onFeedbackRequest.bind(this)}
          onSMSRequest={this.onSMSRequest.bind(this)}
          onWebChatRequest={this.onWebChatRequest.bind(this)}
          onMenuOpenClose={this.onContactTriggerMenuOpenClose.bind(this)}
          webChatAgentStatus={this.state.webChatAgentStatus}
          webChatStatus={this.state.webChatStatus}
          allowWebChat={this.isAllowWebChat()}
          open={this.state.contactMenuOpen}
        />
      )
    }

    let footer
    let fixed = true
    if (footerVisible) {
      fixed = false
      footer = (
        <div className="no-print">
          <MainFooter
            intl={this.props.intl}
            selectedSearchObj={selectedSearchObj}
            isLoading={isLoading}
            savedSearchFilter={savedSearchFilter}
            adConfig={getAdConfig(mainConfig)}
            mainConfig={mainConfig}
            onFeedbackRequest={this.onFeedbackRequest.bind(this)}
          />
          {feedbackTrigger}
        </div>
      )
    }

    let header
    if (headerVisible) {
      header = (
        <Header
          homeConfig={homeConfig}
          mainConfig={mainConfig}
          fixed={fixed}
          displayMenu={hasInlineSearch}
          theme={this.props.theme}
          getLocale={this.props.getLocale}
          intl={this.props.intl}
          route={this.props.route}
          location={this.props.location}
          inlineSearch={hasInlineSearch}
          inlineSearchEnabled={!isLoading && searchVisible}
          isOverlay
          userProfile={this.props.userProfile}
          allQuickSearchResults={allQuickSearchResults}
          allQuickSearchResultsAttribs={allQuickSearchResultsAttribs}
          mainSearchConfig={mainSearchConfig}
          brndConf={brndConf}
          onSearchTrigger={onSearchTrigger}
          onSearchTermChange={onSearchTermChange}
          onSearchResultItemSelectRequest={onSearchResultItemSelectRequest}
          cartTimeToExpiry={cartTimeToExpiry}
          cartItemCount={cartItemCount}
          currSearch={currSearch}
          addToFavoritesCtr={addToFavoritesCtr}
          removeFromFavoritesCtr={removeFromFavoritesCtr}
          onSignOutRequest={this.onSignOutRequest.bind(this)}
          headerTraceIdEnabled={!headerTraceIdNotVisible}
        />
      )
    }

    return (
      <div className="app-container">
        <MetaData
          intlObj={intl}
          literalValues={getLiteralValuesForApp(mainConfig)}
          data={metaConfig}
          pageId={PAGE_ID}
        />
        <SRLoadAnnouncer
          isLoading={this.props.isLoading}
          loadingMsg={simpleTranslate(
            intl,
            'labels_generic_loading',
            'Loading',
          )}
          loadCompleteMsg={simpleTranslate(
            intl,
            'labels_generic_loading_complete',
            'Loading completed.',
          )}
        />
        <Spinner
          loading={this.props.isLoading || showLoader}
          label={this.props.loaderText}
        />
        <Cont cmpLandmark={LANDMARKS.NAV}>{header}</Cont>
        <Cont cmpLandmark={LANDMARKS.MAIN}>{this.props.children}</Cont>
        <Cont cmpLandmark={LANDMARKS.FOOTER}>{footer}</Cont>
        <AModal
          show={this.state.feedbackModalOpen}
          onHide={this.closeFeedbackModal.bind(this)}
        >
          <AModalHeader closeButton>
            <AClearfix />
          </AModalHeader>
          <AModalBody>
            <FeedbackSurvey
              intl={intl}
              theme={theme}
              feedbackConfig={feedbackConfig}
              mainConfig={mainConfig}
              onFeedbackSendRequest={this.onFeedbackSendRequest.bind(this)}
              onFeedbackCancelRequest={this.onFeedbackCancelRequest.bind(this)}
              location={this.props.location}
            />
          </AModalBody>
        </AModal>
        {this.getSignOutWarningModal()}
        {this.getRedirectWarningModal()}
        {this.getSMSModal()}
        {this.getToggleCartModal()}
        <AAlert />

        {/* <KeyboardShortcuts
          onToggleCart={() => this.props.toggleCart({ chub_cart: !this.props.chubcartConfig.default.chub_cart })}
          onToggleSW={() => this.props.toggleCart({ allow_cart_expansion_insurance: !this.props.chubcartConfig.default.allow_cart_expansion_insurance })}
          onToggleVouchers={() => this.props.toggleCart({ allow_partial_payments: !this.props.chubcartConfig.default.allow_partial_payments })}
        /> */}
      </div>
    )
  }

  static propTypes = {
    router: object,
    changeTheme: func.isRequired,
    setRootInfo: func.isRequired,
    isLoading: bool.isRequired,
    showLoader: bool.isRequired,
    rootTheme: string,
    rootBrand: string,
    rootTraceId: string,
    getLocalState: func.isRequired,
    locale: string,
    getLocale: func.isRequired,
    params: object,
    intl: object.isRequired,
    route: object,
    userProfile: object.isRequired,
    children: object,
    location: object,
    theme: string,
    cartTimeToExpiry: object.isRequired,
    cartItemCount: number.isRequired,
    onRequestFeedback: func.isRequired,
    redirectConfig: object.isRequired,
    launchURL: string.isRequired,
    onFeedbackSendRequest: func,
    allQuickSearchResults: arrayOf(any),
    allQuickSearchResultsAttribs: arrayOf(any),
    mainSearchConfig: object,
    brndConf: object,
    onSearchTrigger: func,
    onSearchTermChange: func,
    onSearchResultItemSelectRequest: func,
    selectedSearchObj: object,
    currSearch: object,
    savedSearchFilter: object,
    homeConfig: object,
    mainConfig: object,
    feedbackConfig: object,
    addToFavoritesCtr: any,
    removeFromFavoritesCtr: any,
    metaConfig: object,
    toggleCartModal: func,
    toggleCart: func,
    clearChubCart: func,
    chubcartConfig: object,
    requestFeedbackCtr: number,
    redirectCtr: number,
    cartMixpanelPayload: cartMixpanelPayloadShape,
    loaderText: string,
  }
}

function mapStateToProps(state) {
  return {
    rootTheme: root.theme,
    rootBrand: root.brand,
    rootTraceId: root.traceId,
    theme: state.application.theme,
    isLoading: state.backend.isLoading,
    showLoader: state.backend.showLoader,
    locale: state.backend.intl.locale,
    messages: state.backend.intl.messages,
    intl: state.backend.intl,
    userProfile: state.backend.user.profile,
    cartMixpanelPayload: selectCartMixpanelPayload(state),
    allQuickSearchResults: getAllQuickSearchResults(
      state.backend.search.quickSearch,
      state.backend.config.main.mainSearch,
    ),
    allQuickSearchResultsAttribs: getAllSearchProviderSourceAttribs(
      state.backend.config.main.mainSearch,
    ),
    mainSearchConfig: state.backend.config.main,
    brndConf: state.backend.config.brand,
    homeConfig: state.backend.config.home,
    mainConfig: state.backend.config.main,
    metaConfig: state.backend.config.meta,
    selectedSearchObj: state.backend.search.selectedSearchObj,
    currSearch: state.backend.search,
    cartTimeToExpiry: getTimeToExpiry(
      state.backend.user.profile,
      state.backend.cart.currentCart,
      state.backend.txnTimeStamps,
    ),
    cartItemCount: getItemCount(
      state.backend.user.profile,
      state.backend.cart.currentCart,
      state.backend.txnTimeStamps,
    ),
    savedSearchFilter: state.backend.search.searchFilter,
    feedbackConfig: state.backend.config.feedback,
    addToFavoritesCtr: state.backend.favorites.addToFavoritesCtr,
    removeFromFavoritesCtr: state.backend.favorites.removeFromFavoritesCtr,
    requestFeedbackCtr: state.backend.counters.feedback.request,
    redirectCtr: state.backend.counters.redirect.request,
    redirectConfig: state.backend.redirect.redirectConfig,
    launchURL: state.backend.launchURL,
    chubcart: state.backend.chubcart,
    chubcartConfig: state.backend.chubcartConfig,
    loaderText: state.backend.loaderText,
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSearchTrigger: (
      searchTerm,
      selectedItem,
      savedSearchFilter,
      currSearch,
    ) => {
      mixpanel.searchFromHeader(searchTerm, selectedItem)
      if (isEmpty(searchTerm)) {
        // dispatch(throwError(createErrorFromObj(errMsgs.searchNoInput)))
      } else {
        let clearSearch = true
        let searchFilter = {}
        const currSearchSelected = isCurrSearchSelected(
          currSearch,
          selectedItem,
        )
        if (currSearchSelected) {
          clearSearch = false
          searchFilter = savedSearchFilter
        }
        onSearchTriggerUtil(dispatch, searchTerm, selectedItem, clearSearch)

        const route = getCurrentPageRoute(
          getPathname(ownProps, ownProps.launchURL),
        )

        mixpanel.searchInSearchBar(
          selectedItem?.searchString,
          searchTerm,
          route,
        )

        let queryObj = {}
        const mainSearchQueryObj = getMainSearchObj(
          true,
          getSearchObj(searchTerm, selectedItem),
        )
        queryObj = mergeQueries([mainSearchQueryObj, searchFilter], queryObj)
        forwardToSearchResults(queryObj)
      }
    },
    onSearchTermChange: (searchTerm, mainSearchConfig, brandConfig) => {
      onSearchTermChangeUtil(
        dispatch,
        searchTerm,
        mainSearchConfig,
        brandConfig,
      )
    },
    onSearchResultItemSelectRequest: (
      selectedItem,
      savedSearchFilter,
      currSearch,
    ) => {
      let clearSearch = true
      let searchFilter = {}
      const currSearchSelected = isCurrSearchSelected(currSearch, selectedItem)
      if (currSearchSelected) {
        clearSearch = false
        searchFilter = savedSearchFilter
      }

      onSearchResultItemSelectRequestUtil(dispatch, selectedItem, clearSearch)

      const route = getCurrentPageRoute(
        getPathname(ownProps, ownProps.launchURL),
      )
      mixpanel.searchInSearchBar(
        selectedItem?.searchString,
        selectedItem?.name,
        route,
      )

      let queryObj = {}
      const mainSearchQueryObj = getMainSearchObj(
        true,
        getSearchObj(selectedItem.name, selectedItem),
      )
      queryObj = mergeQueries([mainSearchQueryObj, searchFilter], queryObj)
      forwardToSearchResults(queryObj)
    },
    getLocale: (locale, is) => {
      dispatch(getLocale(locale, is))
    },
    changeTheme: (theme) => {
      dispatch(changeTheme(theme))
    },
    setRootInfo: (rootInfo) => {
      dispatch(setRootInfo(rootInfo))
    },
    getLocalState: () => {
      dispatch(getLocalState())
    },
    onRequestFeedback: () => {
      dispatch(requestFeedback(FEEDBACK_GROUPS.NONCHECKOUT))
    },
    onFeedbackSendRequest: (feedbackGroup, surveyResponse) => {
      dispatch(sendFeedback(feedbackGroup, surveyResponse))
    },
    toggleCart: (chubcartConfig) => {
      dispatch(chubToggleCart(chubcartConfig))
    },
    toggleCartModal: (isVisible) => {
      dispatch(chubToggleCartModal(isVisible))
    },
    clearChubCart: (chubCartId, cartItems) => {
      dispatch(chubRemoveCart(chubCartId, cartItems))
    },
  }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, ownProps, {
    ...stateProps,
    ...dispatchProps,
    onSearchTrigger: (searchTerm, selectedItem) =>
      dispatchProps.onSearchTrigger(
        searchTerm,
        selectedItem,
        stateProps.savedSearchFilter,
        stateProps.selectedSearchObj,
      ),
    onSearchResultItemSelectRequest: (selectedItem) =>
      dispatchProps.onSearchResultItemSelectRequest(
        selectedItem,
        stateProps.savedSearchFilter,
        stateProps.selectedSearchObj,
      ),
    toggleCart: (chubcartConfig) => dispatchProps.toggleCart(chubcartConfig),
    toggleCartModal: () =>
      dispatchProps.toggleCartModal(
        !stateProps.chubcartConfig.ui.show_toggle_cart_modal,
      ),
    clearChubCart: () =>
      dispatchProps.clearChubCart(
        stateProps.chubcart.cartId,
        stateProps.chubcart.cartItems,
      ),
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(withRouter(App))
