/**
 * @file   : FacilitySummarySimple.js
 * @author :  ()
 * @date   : 8/12/2019, 6:59:19 PM
 */
import PropTypes from 'prop-types'
import React from 'react'

import Cont from '../../../external/components/layout/Cont'
import ABaseLink from '../../../external/components/link/ABaseLink'
import AImg from '../../../external/components/media/img/AImg'
import TextStyler, {
  CARD_HEADER,
  DEFAULT_TEXT,
} from '../../../external/components/text/TextStyler'
import {
  isEmpty,
  isUndefined,
  isValue,
} from '../../../external/utilities/GeneralUtils'
import { ROLES } from '../../../external/utilities/accessibility/Definitions'
import Translation from '../../Translation'
import RatingCmp from '../../shared/ratings/RatingCmp'

const FacilitySummarySimple = ({
  intl,
  facilitySummary,
  getFacilitySummaryLinkObj,
  clientLogoSource,
  onFacilitySelectRequest,
}) => {
  if (isUndefined(facilitySummary) || isEmpty(facilitySummary)) {
    return null
  }

  let imageStyle = null
  let affiliatedStates = null
  let clientLogo = null
  let proximity = null
  let ratingsSection = null

  const isRecentlyViewed = isEmpty(facilitySummary.details)
  const a1ImgUrl = isRecentlyViewed
    ? facilitySummary.thumbnailImageURL
    : facilitySummary.details.imageURL

  const imageURL = facilitySummary.a1Id
    ? facilitySummary.photos?.[0]?.original
    : a1ImgUrl

  const stateCodes = isRecentlyViewed
    ? facilitySummary.stateCodes
    : facilitySummary.details.stateCodes

  if (!isEmpty(imageURL)) {
    imageStyle = { backgroundImage: `url(${imageURL})` }
  }

  if (!isEmpty(stateCodes)) {
    const states = stateCodes.map((state, key) => (
      <span key={key}>{state} </span>
    ))

    affiliatedStates = <span>, {states}</span>
  }

  if (!isEmpty(facilitySummary.logoSrc)) {
    clientLogoSource = facilitySummary.logoSrc
  }
  if (!isUndefined(clientLogoSource) && !isEmpty(clientLogoSource)) {
    clientLogo = (
      <Cont className={'facility-nearby-card__clientlogo'}>
        <AImg
          imageURL={clientLogoSource}
          cmpLabel={facilitySummary.name}
          className={'client-logo'}
        />
      </Cont>
    )
  }

  const distance =
    facilitySummary.distance !== undefined
      ? facilitySummary.distance.toFixed(2)
      : facilitySummary.proximity

  if (isValue(distance) && distance > 0) {
    proximity = (
      <div className="facility-summary-card__distance">
        <TextStyler styleObj={{ ...DEFAULT_TEXT }}>
          <Translation
            intl={intl}
            mkey="labels_search_proximity"
            literalValues={{ distance }}
          />
        </TextStyler>
      </div>
    )
  }

  if (
    !isEmpty(facilitySummary.details) &&
    facilitySummary.details.ratingSupport &&
    !isEmpty(facilitySummary.details.rating)
  ) {
    ratingsSection = (
      <div className="facility-summary-card__ratings">
        <RatingCmp
          rating={facilitySummary.details.rating}
          facilityName={facilitySummary.details.name}
          intl={intl}
        />
      </div>
    )
  }

  const getAveragePriceUI = () => {
    const { spots = [] } = facilitySummary || {}

    const availableSlotsWithPricePerNight = [...spots]
      .filter((spot) => spot.available && spot.price)
      .sort((current, next) =>
        Number(current.price) > Number(next.price) ? 1 : -1,
      )

    if (availableSlotsWithPricePerNight.length) {
      return (
        <span>
          <Translation
            intl={intl}
            mkey="labels_price_per_night"
            literalValues={{
              pricePerNight: availableSlotsWithPricePerNight[0].price,
            }}
          />
        </span>
      )
    }

    return <></>
  }

  return (
    <Cont>
      <div className={'facility-nearby-card__container'}>
        {facilitySummary.a1Id && (
          <div className="ribbon">
            <Translation intl={intl} mkey="labels_facility_available" />
          </div>
        )}

        <ABaseLink
          {...getFacilitySummaryLinkObj(facilitySummary)}
          tabIndex={-1}
          onClick={() => {
            onFacilitySelectRequest(facilitySummary)
          }}
        >
          <div className={'facility-nearby-card__image'} style={imageStyle}>
            <Cont
              asSpan
              cmpSRonly
              cmpRole={ROLES.DOC_STRUCTURE.IMG}
              cmpLabel={facilitySummary.name}
            >
              {facilitySummary.name}
            </Cont>
          </div>
        </ABaseLink>
        <Cont className={'facility-nearby-card__details'}>
          <Cont>
            <Cont>{clientLogo}</Cont>
            <Cont className={'facility-nearby-card__name'}>
              <ABaseLink
                {...getFacilitySummaryLinkObj(facilitySummary)}
                onClick={() => {
                  onFacilitySelectRequest(facilitySummary)
                }}
                cmpTitle={facilitySummary.name}
              >
                <span className="facility-summary-card__title">
                  <TextStyler styleObj={{ ...CARD_HEADER }}>
                    {facilitySummary.name}
                    {affiliatedStates}
                  </TextStyler>
                </span>
              </ABaseLink>
            </Cont>
          </Cont>
          <Cont>{ratingsSection}</Cont>
          <Cont>{getAveragePriceUI()}</Cont>
          <Cont>{proximity}</Cont>
        </Cont>
      </div>
    </Cont>
  )
}

FacilitySummarySimple.propTypes = {
  intl: PropTypes.object.isRequired,
  facilitySummary: PropTypes.object,
  getFacilitySummaryLinkObj: PropTypes.func.isRequired,
  clientLogoSource: PropTypes.string,
  onFacilitySelectRequest: PropTypes.func.isRequired,
}

export default FacilitySummarySimple
