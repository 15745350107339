import { differenceInSeconds } from 'date-fns'
import React from 'react'

import { add, format, subtract } from '../components/Currency'
import Translation, { simpleTranslate } from '../components/Translation'
import process from '../environment.json'
import ErrorInline, {
  getInlineError,
} from '../external/components/error/ErrorInline'
import ACol from '../external/components/layout/ACol'
import ARow from '../external/components/layout/ARow'
import Cont, { TEXT_ALIGN_RIGHT } from '../external/components/layout/Cont'
import Divider from '../external/components/layout/Divider'
import {
  findObjectInArray,
  isEmpty,
  isValue,
} from '../external/utilities/GeneralUtils'
import { defaultFormatDateLiteral } from '../external/utilities/formatDate'

export const cartParams = []
cartParams['PrimaryOccupant]'] = {
  name: 'OrderFeeType[ItemFee]',
  token: 'labels_cart_fee_type_item_fee',
  order: 1,
}

export const allInventoryStatus = []
allInventoryStatus.Held = {
  name: 'Held',
  msgToken: '',
  showMsg: false,
  msgStyle: '',
}
allInventoryStatus.Released = {
  name: 'Released',
  msgToken: 'inventory_status_released',
  showMsg: true,
  msgStyle: 'cart-msg-danger',
}
allInventoryStatus['Relock Failed'] = {
  name: 'Relock Failed',
  msgToken: 'inventory_status_relock_failed',
  showMsg: true,
  msgStyle: 'cart-msg-danger',
}

export const allCartProcessStatus = []
allCartProcessStatus.NOTREADYTOCHECKOUT = {
  name: 'NOTREADYTOCHECKOUT',
  msgToken: '',
  processComplete: false,
}
allCartProcessStatus.PROCESSINPROGRESS = {
  name: 'PROCESSINPROGRESS',
  msgToken: '',
  processComplete: false,
}
allCartProcessStatus.INVALID_PAYMENT_INPUT = {
  name: 'INVALID_PAYMENT_INPUT',
  msgToken: '',
  processComplete: false,
}
allCartProcessStatus.SUCCESSFULLY_PROCESSED = {
  name: 'SUCCESSFULLY_PROCESSED',
  msgToken: '',
  processComplete: true,
}
allCartProcessStatus.PARTIALLY_PROCESSSED = {
  name: 'PARTIALLY_PROCESSSED',
  msgToken: '',
  processComplete: false,
}
allCartProcessStatus.NOTPROCESSED = {
  name: 'NOTPROCESSED',
  msgToken: '',
  processComplete: false,
}

export const allContractCartProcessStatus = []
allContractCartProcessStatus.PROCESSSED = {
  name: 'PROCESSSED',
  msgToken: 'order_status_processed',
  typeRequired: true,
  processComplete: true,
}
allContractCartProcessStatus.INVALID_PAYMENT = {
  name: 'INVALID_PAYMENT',
  msgToken: 'order_status_invalid_payment',
  typeRequired: false,
  processComplete: false,
}
allContractCartProcessStatus.PAYMENT_DECLINED = {
  name: 'PAYMENT_DECLINED',
  msgToken: 'order_status_payment_declined',
  typeRequired: false,
  processComplete: false,
}
allContractCartProcessStatus.PROCESS_ERROR = {
  name: 'PROCESS_ERROR',
  msgToken: 'order_status_process_error',
  typeRequired: false,
  processComplete: false,
}
allContractCartProcessStatus.SYSTEM_ERROR = {
  name: 'SYSTEM_ERROR',
  msgToken: 'order_status_system_error',
  typeRequired: false,
  processComplete: false,
}

export function isEmptyCart(cart) {
  let empty = true
  if (
    cart !== undefined &&
    !isEmpty(cart.contractViewList) &&
    cart.contractViewList.length > 0
  ) {
    empty = false
  }

  return empty
}

export function getCartId() {
  return 0
}

export function getContractOrderCount(contractCart) {
  let orders = 0
  if (!isEmpty(contractCart.cartOrderViews)) {
    orders = contractCart.cartOrderViews.length
  }

  return orders
}

export function getContractCartsCount(cart) {
  let orders = 0
  if (!isEmptyCart(cart)) {
    orders = cart.contractViewList.length
  }

  return orders
}

export function isCartProcessRequested(cart) {
  let isProcessRequested = false
  if (
    cart.processStatus !== undefined &&
    cart.processStatus !== null &&
    !isEmpty(cart.processStatus)
  ) {
    if (
      cart.processStatus !== allCartProcessStatus.NOTREADYTOCHECKOUT.name &&
      cart.processStatus !== allCartProcessStatus.PROCESSINPROGRESS.name
    ) {
      isProcessRequested = true
    }
  }

  return isProcessRequested
}

export function isCartPartiallyOrFullyProcessed(processStatus) {
  let isProcessed = false
  if (
    processStatus !== undefined &&
    processStatus !== null &&
    !isEmpty(processStatus)
  ) {
    if (
      processStatus !== allCartProcessStatus.NOTREADYTOCHECKOUT.name &&
      processStatus !== allCartProcessStatus.PROCESSINPROGRESS.name
    ) {
      isProcessed = true
    }
  }

  return isProcessed
}

export function isCartReadyToProcess(cart) {
  let isReady = true
  if (isEmptyCart(cart)) {
    isReady = false
  } else if (
    cart.processStatus !== undefined &&
    cart.processStatus !== null &&
    !isEmpty(cart.processStatus)
  ) {
    if (cart.processStatus === allCartProcessStatus.NOTREADYTOCHECKOUT.name) {
      isReady = false
    }
  }

  return isReady
}

export function hasCartErrors(cart) {
  let hasCartErrors = false
  if (!isEmptyCart(cart)) {
    const allOrders = getAllOrders(cart)
    for (const order of allOrders) {
      // test for details needed
      if (order.detailsNeeded || order.otherDetailsRequired) {
        hasCartErrors = true
        break
      }
      // test for inventory exception
      if (
        !isEmpty(order) &&
        !isEmpty(order.webOrder) &&
        !isEmpty(order.webOrder.siteLock)
      ) {
        if (
          order.webOrder.siteLock.status.name ===
          allInventoryStatus['Relock Failed'].name
        ) {
          hasCartErrors = true
          break
        }
      }
      // test for max occupancy exception
      if (
        !isEmpty(order) &&
        !isEmpty(order.webOrder) &&
        order.webOrder.maxCapacitySiteAvailable !== undefined
      ) {
        if (!order.webOrder.maxCapacitySiteAvailable) {
          hasCartErrors = true
          break
        }
      }
    }
  }

  return hasCartErrors
}

export function isContractCartProcessed(contractCart) {
  let isProcessed = false
  if (contractCart !== undefined && !isEmpty(contractCart)) {
    if (
      contractCart.processResult !== undefined &&
      !isEmpty(contractCart.processResult)
    ) {
      if (
        contractCart.processResult.status ===
        allContractCartProcessStatus.PROCESSSED.name
      ) {
        isProcessed = true
      }
    }
  }

  return isProcessed
}

export function isContractCartPaymentDeclined(contractCart) {
  let isPaymentDeclined = false
  if (
    getContractCartProcessStatus(contractCart) ===
    allContractCartProcessStatus.PAYMENT_DECLINED.name
  ) {
    isPaymentDeclined = true
  }

  return isPaymentDeclined
}

export function isContractCartInvalidPayment(contractCart) {
  let isInvalidPayment = false
  if (
    getContractCartProcessStatus(contractCart) ===
    allContractCartProcessStatus.INVALID_PAYMENT.name
  ) {
    isInvalidPayment = true
  }

  return isInvalidPayment
}

export function getContractCartProcessStatus(contractCart) {
  let status = null
  if (contractCart !== undefined && !isEmpty(contractCart)) {
    if (
      contractCart.processResult !== undefined &&
      !isEmpty(contractCart.processResult)
    ) {
      status = contractCart.processResult.status
    }
  }

  return status
}

export function isAllContractCartsProcessed(cart) {
  let isAllProcessed = false
  if (!isEmptyCart(cart)) {
    if (cart.processStatus !== undefined && !isEmpty(cart.processStatus)) {
      if (
        cart.processStatus === allCartProcessStatus.SUCCESSFULLY_PROCESSED.name
      ) {
        isAllProcessed = true
      }
    }
  }

  return isAllProcessed
}

export function isSomeContractCartsProcessed(cart) {
  let isSomeProcessed = false
  if (!isEmptyCart(cart)) {
    if (cart.processStatus !== undefined && !isEmpty(cart.processStatus)) {
      if (
        cart.processStatus ===
          allCartProcessStatus.SUCCESSFULLY_PROCESSED.name ||
        cart.processStatus === allCartProcessStatus.PARTIALLY_PROCESSSED.name
      ) {
        isSomeProcessed = true
      }
    }
  }

  return isSomeProcessed
}

export function isMagazineOfferAvailable(offer) {
  if (
    !isEmpty(offer) &&
    !isEmpty(offer.magazineOffer) &&
    offer.offerRetrievalSuccess
  ) {
    return true
  }

  return false
}

export function getPrimaryOccupant(order) {
  if (
    !isEmpty(order) &&
    !isEmpty(order.webOrder) &&
    !isEmpty(order.webOrder.primaryOccupant)
  ) {
    return order.webOrder.primaryOccupant
  }

  return ''
}

export function getPrimaryOccupantInfo(order) {
  if (
    !isEmpty(order) &&
    !isEmpty(order.webOrder) &&
    !isEmpty(order.webOrder.primaryOccupantInfo)
  ) {
    return order.webOrder.primaryOccupantInfo
  }

  return ''
}

export function getNumberOfOccupants(order) {
  if (
    !isEmpty(order) &&
    !isEmpty(order.webOrder) &&
    order.webOrder.numberOfOccupants !== undefined
  ) {
    return order.webOrder.numberOfOccupants.toString()
  }

  return ''
}

export function getArrivalDate(order, intl) {
  if (
    !isEmpty(order) &&
    !isEmpty(order.webOrder) &&
    !isEmpty(order.webOrder.arrivalDate)
  ) {
    return defaultFormatDateLiteral(order.webOrder.arrivalDate, intl)
  }

  return ''
}

export function getDepartureDate(order, intl) {
  if (
    !isEmpty(order) &&
    !isEmpty(order.webOrder) &&
    !isEmpty(order.webOrder.endDate)
  ) {
    return defaultFormatDateLiteral(order.webOrder.endDate, intl)
  }

  return ''
}

export function getUnformattedArrivalDate(order) {
  if (
    !isEmpty(order) &&
    !isEmpty(order.webOrder) &&
    !isEmpty(order.webOrder.arrivalDate)
  ) {
    return order.webOrder.arrivalDate
  }

  return ''
}

export function getUnformattedDepartureDate(order) {
  if (
    !isEmpty(order) &&
    !isEmpty(order.webOrder) &&
    !isEmpty(order.webOrder.endDate)
  ) {
    return order.webOrder.endDate
  }

  return ''
}

export function getCartTotal(currentCart, category) {
  return (
    currentCart.cartPriceInfo?.amounts.find(
      (item) => item.category === category,
    ) ?? null
  )
}

export function getContractTotal(contractCart, category) {
  let total = null
  if (
    contractCart.contractCartPriceInfo.amounts !== undefined &&
    contractCart.contractCartPriceInfo.amounts.length > 0
  ) {
    for (const item of contractCart.contractCartPriceInfo.amounts) {
      if (item.category === category) {
        total = item
        break
      }
    }
  }

  return total
}

export function getOrderBalance(order) {
  let balance = null
  if (
    order.webOrder !== undefined &&
    order.webOrder.price !== undefined &&
    order.webOrder.price.balance !== undefined
  ) {
    balance = order.webOrder.price.balance
  }

  return balance
}

export function getMaxOccupancyMessage(order, intl) {
  let msg = null
  if (
    !isEmpty(order) &&
    !isEmpty(order.webOrder) &&
    order.webOrder.maxCapacitySiteAvailable !== undefined
  ) {
    if (!order.webOrder.maxCapacitySiteAvailable) {
      msg = simpleTranslate(intl, 'site_max_capacity_exceeded')
    }
  }

  if (msg !== null) {
    return <ErrorInline>{getInlineError(msg)}</ErrorInline>
  }

  return null
}

export function getNotesContent(notes) {
  return notes.messages.map((message, i) => (
    <ErrorInline key={i}>
      {getInlineError(`${notes.headline}: ${message}`)}
    </ErrorInline>
  ))
}

export function getOrderNotes(order) {
  if (!isEmpty(order) && !isEmpty(order.orderNote)) {
    return getNotesContent(order.orderNote)
  }

  return null
}

export function getInventoryStatusMessage(order, intl) {
  let msg = null
  let inventoryStatus = null
  if (
    !isEmpty(order) &&
    !isEmpty(order.webOrder) &&
    !isEmpty(order.webOrder.siteLock)
  ) {
    inventoryStatus = allInventoryStatus[order.webOrder.siteLock.status.name]
    if (!isEmpty(inventoryStatus)) {
      if (inventoryStatus.showMsg) {
        msg = simpleTranslate(intl, inventoryStatus.msgToken)
      }
    }
  }

  if (msg !== null) {
    return <ErrorInline>{getInlineError(msg)}</ErrorInline>
  }

  return null
}

export function getLengthOfStayStatement(order, intl) {
  if (
    !isEmpty(order) &&
    !isEmpty(order.webOrder) &&
    order.webOrder.units !== undefined
  ) {
    const units = order.webOrder.units
    let dayUseSite = true
    if (order.webOrder.unitOfStay === 'NIGHTLY') {
      dayUseSite = false
    }
    if (dayUseSite) {
      if (units < 2) {
        return `${units} (${simpleTranslate(intl, 'labels_cart_day', 'day')})`
      }

      return `${units} (${simpleTranslate(intl, 'labels_cart_days', 'days')})`
    }
    if (units < 2) {
      return `${units} (${simpleTranslate(intl, 'labels_cart_night', 'night')})`
    }

    return `${units} (${simpleTranslate(intl, 'labels_cart_nights', 'nights')})`
  }

  return ''
}

export function isADA(order) {
  let ada = false

  if (!isEmpty(order.webOrder)) {
    ada = order.webOrder.displayAccesibilitySign
  }

  return ada
}

export function getKeyQualifiersForPriceLabels(contractCode, dailyEntry) {
  const keyQual = []
  if (dailyEntry) {
    keyQual.push('dailyEntry')
  }
  keyQual.push(contractCode)

  return keyQual
}

export function getOrderFeeSection(order, intl) {
  let feeContent
  if (order?.orderPriceInfo.orderPriceStatus === 'AVAILABLE') {
    if (
      order?.orderPriceInfo.price.amounts !== undefined &&
      order?.orderPriceInfo.price.amounts.length > 0
    ) {
      feeContent = order?.orderPriceInfo.price.amounts.map((fee, i) => {
        // IntlContext.get().setContractCode(order.contractCode)
        let mainLine
        if (fee.value !== undefined && fee.value !== null) {
          const keyQual = getKeyQualifiersForPriceLabels(
            order.contractCode,
            order.webOrder.dailyEntry,
          )
          mainLine = (
            <div className="cart-main-total-line">
              {showAmountStatementWithCols(
                intl,
                fee.category,
                fee.value,
                fee.type,
                false,
                keyQual,
                fee.label,
              )}
            </div>
          )
        }
        let subLines
        if (
          fee.amountLines !== null &&
          fee.amountLines !== undefined &&
          fee.amountLines.length > 0
        ) {
          subLines = fee.amountLines.map((subFee, j) => (
            <div key={j} className="cart-sub-total-line">
              {showAmountStatementWithCols(
                intl,
                subFee.name,
                subFee.value,
                fee.type,
                false,
                [],
                subFee.name,
                false,
              )}
            </div>
          ))
        }
        // IntlContext.get().clear()

        return (
          <div key={i}>
            {mainLine}
            {subLines}
            <Divider />
          </div>
        )
      })
    }
  }

  return feeContent
}

export function getAllOrders(cart) {
  const allOrders = []
  if (!isEmpty(cart) && !isEmpty(cart.contractViewList)) {
    for (let i = 0; i < cart.contractViewList.length; i++) {
      if (!isEmpty(cart.contractViewList[i].cartOrderViews)) {
        const orders = cart.contractViewList[i].cartOrderViews
        for (let j = 0; j < orders.length; j++) {
          allOrders.push(orders[j])
        }
      }
    }
  }

  return allOrders
}

export function isCartSupported(cart) {
  let isSupported = true
  if (!isEmptyCart(cart)) {
    for (const contractCart of cart.contractViewList) {
      if (contractCart.otherDetailsRequired) {
        isSupported = false
        break
      }
    }
  }

  return isSupported
}

export function buildStatement(intl, labelKey, value) {
  return (
    <div className="cart-statement">
      <Translation mkey={labelKey} intl={intl} /> : {value}
    </div>
  )
}

export function buildStatementWithLabel(labelKey, value) {
  return (
    <div className="cart-statement">
      <Cont asSpan className="bold">
        {labelKey}
      </Cont>{' '}
      : {value}
    </div>
  )
}

export function buildBoldStatement(
  intl,
  labelKey,
  value,
  style = '',
  cmp = null,
) {
  let valueCmp = cmp
  if (valueCmp === null) {
    valueCmp = <span className={style}> {value}</span>
  }

  return (
    <div className="cart-statement">
      <span className="bold">
        <Translation mkey={labelKey} intl={intl} />
      </span>
      {valueCmp}
    </div>
  )
}

export function buildSingleStatement(value, isBold = true) {
  let style = ''
  if (isBold) {
    style = 'bold'
  }

  return (
    <div className="cart-statement">
      <span className={style}>{value}</span>
    </div>
  )
}

export function showAmountStatement(
  intl,
  labelKey,
  numberValue,
  type = 'CHARGE',
  showOnlyIfPresent = true,
  keyQualifiers,
  defaultMessage = '',
) {
  if (isEmpty(defaultMessage)) {
    defaultMessage = ''
  }
  if (numberValue === 0 && showOnlyIfPresent) {
    return <div />
  }

  return (
    <div className="cart-statement money">
      <span className="bold">
        <Translation
          mkey={labelKey}
          intl={intl}
          keyQualifiers={keyQualifiers}
          defaultMessage={defaultMessage}
        />
      </span>{' '}
      {getFormattedCurrencyValue(
        Number.parseFloat(numberValue).toFixed(2),
        type,
      )}
    </div>
  )
}

export function showAmountStatementWithCols(
  intl,
  labelKey,
  numberValue,
  type = 'CHARGE',
  showOnlyIfPresent = true,
  keyQualifiers,
  defaultMessage = '',
  isMainItem = true,
) {
  if (isEmpty(defaultMessage)) {
    defaultMessage = ''
  }
  if (numberValue === 0 && showOnlyIfPresent) {
    return <div />
  }

  let itemNameStyle = ''
  if (isMainItem) {
    itemNameStyle = 'bold'
  }

  return (
    <div>
      <ARow>
        <ACol xs={8} style={'print-col'}>
          <span className={itemNameStyle}>
            <Translation
              mkey={labelKey}
              intl={intl}
              keyQualifiers={keyQualifiers}
              defaultMessage={defaultMessage}
            />
          </span>
        </ACol>
        <ACol xs={4} style={'print-col'}>
          <Cont textAlign={TEXT_ALIGN_RIGHT}>
            {getFormattedCurrencyValue(numberValue, type)}
          </Cont>
        </ACol>
      </ARow>
    </div>
  )
}

export function getFormattedCurrencyValue(
  amountValue,
  type = 'CHARGE',
  currencySymbol = '$',
) {
  if (!isValue(amountValue)) {
    amountValue = 0
  }

  return (
    currencySymbol +
    addCreditBalanceIndicator(format(String(amountValue)), type)
  )
}

export function addCreditBalanceIndicator(amount, type) {
  if (type !== 'CHARGE') {
    return `(${amount})`
  }

  return amount
}

export function getItemCount(userProfile, cart) {
  // cart should take precendence as cart will usually be updated, user profile may be old
  let itemCount = 0
  if (!isEmpty(cart)) {
    const allOrders = getAllOrders(cart)
    itemCount = allOrders.length
  } else if (!isEmpty(userProfile.cart)) {
    itemCount = parseInt(userProfile.cart.itemsCount)
  }

  return itemCount
}

export function getDefaultPaymentAmountChoiceCode(contractCart) {
  let defaultAmountPaymentChoice = ''
  if (contractCart !== undefined && !isEmpty(contractCart)) {
    defaultAmountPaymentChoice =
      contractCart.checkoutOptions.paymentOptions.defaultPaymentChoice
  }

  return defaultAmountPaymentChoice
}

export function getDefaultPaymentAmountChoice(contractCart) {
  return getPaymentAmountChoiceFromCode(
    contractCart,
    getDefaultPaymentAmountChoiceCode(contractCart),
  )
}

export function getPaymentAmountChoiceFromCode(
  contractCart,
  paymentChoiceCode,
) {
  let paymentAmountChoice = null
  if (contractCart !== undefined && !isEmpty(contractCart)) {
    paymentAmountChoice = findObjectInArray(
      contractCart.checkoutOptions.paymentOptions.paymentAmountOptions,
      'amountChoice',
      paymentChoiceCode,
    )
  }

  return paymentAmountChoice
}

export function getContractCode(contractCart) {
  return contractCart.contractCode
}

export function getInitialCalculatedTotalsForAllContractCarts(cart) {
  const calculatedTotals = {}
  if (cart !== undefined && !isEmpty(cart) && !isEmpty(cart.contractViewList)) {
    for (const contractCart of cart.contractViewList) {
      const calculatedTotal = getContractCartBalance(
        contractCart,
        getDefaultPaymentAmountChoice(contractCart),
      )
      const contractCode = getContractCode(contractCart)
      calculatedTotals[contractCode] = calculatedTotal
    }
  }

  return calculatedTotals
}

export function getCalculatedTotalForOrder(order = {}) {
  let orderItemTotal = 0

  if (
    !isEmpty(order?.orderPriceInfo) &&
    !isEmpty(order?.orderPriceInfo.price)
  ) {
    for (const amount of order?.orderPriceInfo.price.amounts ?? []) {
      if (amount.type === 'CHARGE') {
        orderItemTotal = add(orderItemTotal, amount.value)
      } else if (amount.type === 'DISCHARGE') {
        if (amount.value !== null && amount.value > 0) {
          orderItemTotal = subtract(orderItemTotal, amount.value)
        } else if (!isEmpty(amount.amountLines)) {
          for (const amountLine of amount.amountLines) {
            orderItemTotal = subtract(orderItemTotal, amountLine.value)
          }
        }
      }
    }
  }

  return orderItemTotal.toString()
}

export function isAnyContractCalculatedBalanceOwing(
  cart,
  calculatedContractBalances,
) {
  let isOwing = false
  if (cart !== undefined && !isEmpty(cart) && !isEmpty(cart.contractViewList)) {
    for (const contractCart of cart.contractViewList) {
      const contractCode = getContractCode(contractCart)
      if (
        calculatedContractBalances[contractCode] !== undefined &&
        calculatedContractBalances[contractCode] > 0
      ) {
        isOwing = true
        break
      }
    }
  }

  return isOwing
}

export function getContractCartBalance(
  contractCart,
  selectedPaymentAmountChoice,
) {
  return selectedPaymentAmountChoice.amount
}

export function isPaymentAmountChoiceSupported(
  selectedPaymentAmountChoice,
  allowedPaymentAmountOptions,
) {
  let allowed = false
  for (const option of allowedPaymentAmountOptions) {
    if (option === selectedPaymentAmountChoice.amountChoice) {
      allowed = true
      break
    }
  }

  return allowed
}

export function getCalculatedBalanceObj(
  contractCart,
  selectedPaymentAmountChoice,
  currentlyAppliedVouchers,
  currentlyAppliedGiftCards,
  voucherAllowedPaymentAmountChoices,
  giftCardAllowedPaymentAmountChoices,
) {
  const contractCartBalance = getContractCartBalance(
    contractCart,
    selectedPaymentAmountChoice,
  )
  let remainingBalance = contractCartBalance
  const isVoucherAllowedForPaymentAmountChoice = isPaymentAmountChoiceSupported(
    selectedPaymentAmountChoice,
    voucherAllowedPaymentAmountChoices,
  )
  const isGiftCardAllowedForPaymentAmountChoice =
    isPaymentAmountChoiceSupported(
      selectedPaymentAmountChoice,
      giftCardAllowedPaymentAmountChoices,
    )
  const balanceObj = {}
  balanceObj.currentlyAppliedVouchers = []
  balanceObj.currentlyAppliedGiftCards = []
  balanceObj.currentlyAppliedVoucherBalances = []
  balanceObj.currentlyAppliedGiftCardBalances = []

  // vouchers first
  if (isVoucherAllowedForPaymentAmountChoice) {
    for (const voucher of currentlyAppliedVouchers) {
      if (remainingBalance > 0) {
        const balanceUsedRemainingObject = getBalanceUsedRemainingObject(
          voucher.voucherNumber,
          remainingBalance,
          voucher.value,
        )
        remainingBalance = subtract(
          remainingBalance,
          balanceUsedRemainingObject.used,
        )
        balanceObj.currentlyAppliedVouchers.push(voucher)
        balanceObj.currentlyAppliedVoucherBalances.push(
          balanceUsedRemainingObject,
        )
      }
    }
  }

  if (isGiftCardAllowedForPaymentAmountChoice) {
    for (const giftCard of currentlyAppliedGiftCards) {
      if (remainingBalance > 0) {
        const balanceUsedRemainingObject = getBalanceUsedRemainingObject(
          giftCard.giftCardNumber,
          remainingBalance,
          giftCard.totalValue,
        )
        remainingBalance = subtract(
          remainingBalance,
          balanceUsedRemainingObject.used,
        )
        balanceObj.currentlyAppliedGiftCards.push(giftCard)
        balanceObj.currentlyAppliedGiftCardBalances.push(
          balanceUsedRemainingObject,
        )
      }
    }
  }

  if (remainingBalance < 0) {
    remainingBalance = 0
  }
  balanceObj.calculatedBalance = remainingBalance

  return balanceObj
}

export function addOtherPaymentData(
  data,
  selectedPaymentAmountChoice,
  currentlyAppliedVouchers,
  currentlyAppliedGiftCards,
) {
  data.paymentAmountChoice = selectedPaymentAmountChoice.amountChoice
  data.vouchers = []
  data.giftCardsInfo = []

  for (const voucher of currentlyAppliedVouchers) {
    data.vouchers.push(voucher.voucherNumber)
  }

  for (const giftCard of currentlyAppliedGiftCards) {
    const giftCardInfo = {}
    giftCardInfo.gcNumber = giftCard.giftCardNumber
    giftCardInfo.gcPin = giftCard.giftCardPin
    giftCardInfo.paymentTypeCode = giftCard.paymentType.code
    giftCardInfo.contractCode = giftCard.contractCode

    data.giftCardsInfo.push(giftCardInfo)
  }

  return data
}

export function getBalanceUsedRemainingObject(id, totalRemaining, value) {
  const balanceObj = {}
  balanceObj.id = id
  let used = value
  let remaining = 0
  if (totalRemaining <= value) {
    used = totalRemaining
    remaining = subtract(value, used)
  }
  balanceObj.used = used
  balanceObj.remaining = remaining

  return balanceObj
}

export function isCreditcardUsedForPayment(contractCart) {
  // contractCart.payments[0].paymentDisplayInfo
  let isCC = false
  if (
    contractCart.payments !== undefined &&
    !isEmpty(contractCart.payments) &&
    contractCart.payments.length > 0 &&
    contractCart.payments[0].paymentDisplayInfo !== undefined &&
    !isEmpty(contractCart.payments[0].paymentDisplayInfo)
  ) {
    isCC = true
  }

  return isCC
}

export function isNonSpecificSite(currentCart) {
  let isNSS = false
  const allOrders = getAllOrders(currentCart)
  for (const order of allOrders) {
    if (order.orderType === 'campsite' && order.webOrder.site.nonSpecific) {
      isNSS = true
      break
    }
  }

  return isNSS
}

export function getGiftCardOptions(contractCart) {
  const options =
    contractCart?.checkoutOptions.paymentOptions.giftCardOptions?.options || []

  return options.length ? options : null
}

export function getVoucherOptions(contractCart) {
  let options = null
  if (
    contractCart !== undefined &&
    contractCart.checkoutOptions.paymentOptions.voucherOptions.options !==
      undefined &&
    !isEmpty(
      contractCart.checkoutOptions.paymentOptions.voucherOptions.options,
    ) &&
    contractCart.checkoutOptions.paymentOptions.voucherOptions.options.length >
      0
  ) {
    options = contractCart.checkoutOptions.paymentOptions.voucherOptions.options
  }

  return options
}

export function getTimeToExpiry(userProfile, cart, txnTimeStamps) {
  // cart should take precendence as cart will usually be updated, user profile may be old
  // console.log('getTimeToExpiry-------- ')
  let timeToExpiry = 0

  let useTxn = null
  let txnTimeStamp = null
  if (txnTimeStamps.cart !== null && txnTimeStamps.user !== null) {
    if (txnTimeStamps.cart > txnTimeStamps.user) {
      useTxn = 'cart'
    } else {
      useTxn = 'user'
    }
  } else if (txnTimeStamps.user) {
    useTxn = 'user'
  } else if (txnTimeStamps.cart) {
    useTxn = 'cart'
  } else {
    // handle server side rendering
    useTxn = 'user'
  }
  // console.log('**timer** txnTimeStamps.user ' + txnTimeStamps.user)
  // console.log('**timer** txnTimeStamps.cart ' + txnTimeStamps.cart)
  // console.log('**timer** useTxn ' + useTxn)

  if (useTxn !== null) {
    if (useTxn === 'user') {
      txnTimeStamp = txnTimeStamps.user
      if (
        !isEmpty(userProfile.cart) &&
        userProfile.cart.timeleftInSec !== null
      ) {
        timeToExpiry = parseInt(userProfile.cart.timeleftInSec)
        const elapsedTime = getElapsedTime(txnTimeStamps.user)
        if (elapsedTime > 0) {
          timeToExpiry -= elapsedTime
        }
        // console.log('**timer** userProfile.cart.timeleftInSec ' + userProfile.cart.timeleftInSec)
        // console.log('**timer** timeToExpiry elapsedTime ' + elapsedTime)
        // console.log('**timer** timeToExpiry using ' + useTxn)
        // console.log('**timer** timeToExpiry ' + timeToExpiry)
      }
    } else if (useTxn === 'cart') {
      const timer = cart.timer.timeLeftInMilisec ?? 0

      txnTimeStamp = txnTimeStamps.cart
      if (timer > 0) {
        timeToExpiry = parseInt(timer)
        const elapsedTime = getElapsedTime(txnTimeStamps.cart)
        if (elapsedTime > 0) {
          timeToExpiry -= elapsedTime
        }
        // console.log('**timer** cart.timer.timeLeftInMilisec ' + cart.timer.timeLeftInMilisec)
        // console.log('**timer** timeToExpiry elapsedTime ' + elapsedTime)
        // console.log('**timer** timeToExpiry using ' + useTxn)
        // console.log('**timer** timeToExpiry ' + timeToExpiry)
      }
    }
  }
  const tte = timeToExpiry * 1000
  // console.log(`**timer** tte ${tte}`)
  const tteObj = {
    timeToExpiry: tte,
    txnTimeStamp,
  }

  return tteObj
}

function getElapsedTime(timeStamp) {
  return differenceInSeconds(timeStamp, Date.now())
}

export function getLatitudeFromOrderLocation(cart) {
  const orders = cart.cartOrderViews
  let lat = 0
  if (!isEmpty(orders)) {
    for (const order of orders) {
      if (!isEmpty(order.coordinates)) {
        lat = order.coordinates.latitude
        break
      }
    }
  }

  return lat
}

export function getLongitudeFromOrderLocation(cart) {
  const orders = cart.cartOrderViews
  let long = 0
  if (!isEmpty(orders)) {
    for (const order of orders) {
      if (!isEmpty(order.coordinates)) {
        long = order.coordinates.longitude
        break
      }
    }
  }

  return long
}

export function getCountryFromContractCart(cart) {
  const orders = cart.cartOrderViews
  let facilityCountry = null
  if (!isEmpty(orders)) {
    for (const order of orders) {
      if (
        !isEmpty(order.contactAddress) &&
        !isEmpty(order.contactAddress.address)
      ) {
        facilityCountry = order.contactAddress.address.country
        break
      }
    }
  }

  return facilityCountry
}

export function getStateCodeFromContractCart(cart) {
  const orders = cart.cartOrderViews
  let stateCode = null
  if (!isEmpty(orders)) {
    for (const order of orders) {
      stateCode = order.contractCode
      break
    }
  }

  return stateCode
}

export function getFullStateNameFromContractCart(brandConfig, cart) {
  const stateCode = getStateCodeFromContractCart(cart)
  let fullStateName = stateCode
  if (!isEmpty(brandConfig) && !isEmpty(brandConfig.states)) {
    for (const state of brandConfig.states) {
      if (state.stateCode === stateCode) {
        fullStateName = state.stateName
        break
      }
    }
  }

  return fullStateName
}

export function getLocationNameFromContractCart(cart) {
  const orders = cart.cartOrderViews
  let locationName = null
  if (!isEmpty(orders)) {
    for (const order of orders) {
      locationName = order.parkName
      break
    }
  }

  return locationName
}

export function getArrivalDateFromContractCart(cart) {
  const orders = cart.cartOrderViews
  let arrivalDay = null
  if (!isEmpty(orders)) {
    for (const order of orders) {
      if (!isEmpty(order.webOrder)) {
        arrivalDay = order.webOrder.arrivalDate
        break
      }
    }
  }

  return arrivalDay
}

export function getDepartureDateFromContractCart(cart) {
  const orders = cart.cartOrderViews
  let departureDay = null
  if (!isEmpty(orders)) {
    for (const order of orders) {
      if (!isEmpty(order.webOrder)) {
        departureDay = order.webOrder.endDate
        break
      }
    }
  }

  return departureDay
}

export function getContractCodesForAllContractCarts(cart) {
  const contractCodes = []
  if (cart !== undefined && !isEmpty(cart) && !isEmpty(cart.contractViewList)) {
    for (const contractCart of cart.contractViewList) {
      contractCodes.push(getContractCode(contractCart))
    }
  }

  return contractCodes
}

export function getFacilityIdsForAllOrders(cart) {
  const facilityIds = []
  const allOrders = getAllOrders(cart)
  for (const order of allOrders) {
    if (isValue(order.parkId)) {
      facilityIds.push(order.parkId)
    }
  }

  return facilityIds
}

export function getAllOrdersForContract(cart, contractCode) {
  const allOrders = []
  if (!isEmpty(cart) && !isEmpty(cart.contractViewList)) {
    for (let i = 0; i < cart.contractViewList.length; i++) {
      if (
        !isEmpty(cart.contractViewList[i].cartOrderViews) &&
        contractCode === getContractCode(cart.contractViewList[i])
      ) {
        const orders = cart.contractViewList[i].cartOrderViews
        for (let j = 0; j < orders.length; j++) {
          allOrders.push(orders[j])
        }
        break
      }
    }
  }

  return allOrders
}

export function getContractCartForContract(cart, contractCode) {
  if (!isEmpty(cart) && !isEmpty(cart.contractViewList)) {
    for (const contractCart of cart.contractViewList) {
      if (contractCode === contractCart.contractCode) {
        return contractCart
      }
    }
  }

  return null
}

export function getSingleCampingOrder(currentCart) {
  let campingOrder = null
  let campingOrderCount = 0
  const allOrders = getAllOrders(currentCart)
  for (const order of allOrders) {
    if (order.orderType === 'campsite') {
      campingOrderCount += 1
      if (campingOrderCount === 1) {
        campingOrder = order
      } else {
        campingOrder = null
      }
    }
  }

  return campingOrder
}

export function getGAECommerceObj(cart) {
  /*
  GA EComerce Reference Objects
  ------------------------------

  Transaction
  ------------
  ga('ecommerce:addTransaction', {
  'id': '1234',                     // Transaction ID. Required.
  'affiliation': 'Acme Clothing',   // Affiliation or store name.
  'revenue': '11.99',               // Grand Total.
  'shipping': '5',                  // Shipping.
  'tax': '1.29'                     // Tax.
  });

  Item
  -----
  ga('ecommerce:addItem', {
  'id': '1234',                     // Transaction ID. Required.
  'name': 'Fluffy Pink Bunnies',    // Product name. Required.
  'sku': 'DD23444',                 // SKU/code.
  'category': 'Party Toys',         // Category or variation.
  'price': '11.99',                 // Unit price.
  'quantity': '1'                   // Quantity.
  });


  Sample Object
  --------------
  {
    transactions: [
      {
        transaction: {
          id: 'New York State',
          revenue: '100.0',
          shipping: '10.0'
        },
        items: [
          {
            id: 'New York State',
            name: 'Campsite',
            sku: '2-12345',
            category: 'Alger Island',
            price: '50.00',
            quantity: '1'
          },
          {
            id: 'New York State',
            name: 'Campsite',
            sku: '2-12346',
            category: 'Allegany State Park',
            price: '50.00',
            quantity: '1'
          }
        ]
      },
      {
        transaction: {
          id: 'Ohio State Parks',
          revenue: '40.0',
          shipping: '5.0'
        },
        items: [
          {
            id: 'Ohio State Parks',
            name: 'Campsite',
            sku: '2-12348',
            category: 'Barkcamp State Park',
            price: '40.00',
            quantity: '1'
          }
        ]
      }
    ]
  }

  GA4 Sample
  gtag("event", "purchase", {
      // This purchase event uses a different transaction ID
      // from the previous purchase event so Analytics
      // doesn't deduplicate the events.
      // Learn more: https://support.google.com/analytics/answer/12313109
      transaction_id: "T_12345_2", //RA - InvoiceID? (used to be contract name)
      affiliation: "Google Merchandise Store", //RA - contractCart.contractName
      value: 25.42, //RA - contractCart.contractPriceView.totalAmountValue.toString()
      tax: 4.90, //RA - contractCart.contractPriceView.taxesAmount
      shipping: 5.99, //RA - N/A
      currency: "USD", //RA - USD
      coupon: "SUMMER_SALE", //RA - N/A
      items: [
      {
        item_id: "SKU_12345",
        item_name: "Stan and Friends Tee",
        affiliation: "Google Merchandise Store",
        coupon: "SUMMER_FUN",
        currency: "USD",
        discount: 2.22,
        index: 0,
        item_brand: "Google",
        item_category: "Apparel",
        item_category2: "Adult",
        item_category3: "Shirts",
        item_category4: "Crew",
        item_category5: "Short sleeve",
        item_list_id: "related_products",
        item_list_name: "Related Products",
        item_variant: "green",
        location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
        price: 9.99,
        quantity: 1
      }]
});

  */

  const contractCodes = getContractCodesForAllContractCarts(cart)
  const transactions = []
  const transactionsGA4 = []

  for (const contractCode of contractCodes) {
    const contractCart = getContractCartForContract(cart, contractCode)
    const allOrders = contractCart.cartOrderViews

    if (isContractCartProcessed(contractCart)) {
      const contractData = {}
      const contractDataGA4 = {}
      const transactionItem = {}
      const transactionItemGA4 = {}

      transactionItem.id = contractCart.contractName
      transactionItemGA4.transaction_id = contractCart.invoiceID.toString()
      transactionItemGA4.affiliation = contractCart.contractName
      transactionItemGA4.currency = 'USD'
      if (contractCart.contractPriceView !== null) {
        transactionItem.revenue =
          contractCart.contractPriceView.totalAmountValue.toString()
        transactionItemGA4.value =
          contractCart.contractPriceView.totalAmountValue
        if (contractCart.contractPriceView.taxesPresent) {
          transactionItem.tax = contractCart.contractPriceView.taxesAmount
          transactionItemGA4.tax = contractCart.contractPriceView.taxesAmount
        }
      }

      contractData.transaction = transactionItem
      contractDataGA4.transaction = transactionItemGA4

      const items = []
      const itemsGA4 = []
      for (const order of allOrders) {
        const item = {}
        const itemGA4 = {}
        item.id = contractCart.contractName
        itemGA4.item_id = order.webOrder.orderNumber
        if (order.orderType === 'posProduct') {
          item.name = order.webOrder.posProductName
          itemGA4.item_name = order.webOrder.posProductName
        } else if (order.orderType === 'serviceOrder') {
          item.name = 'Service Order Fee'
          itemGA4.item_name = 'Service Order Fee'
        } else {
          item.name = order.parkName
          itemGA4.item_name = order.parkName
        }
        item.sku = order.webOrder.orderNumber
        if (order.orderType === 'campsite') {
          item.category = order.webOrder.site.productGroupName
          itemGA4.item_category = order.webOrder.site.productGroupName
        } else if (order.orderType === 'tour') {
          item.category = 'Ticket'
          itemGA4.item_category = 'Ticket'
        } else if (order.orderType === 'posProduct') {
          item.category = 'POS'
          itemGA4.item_category = 'POS'
        } else if (order.orderType === 'serviceOrder') {
          item.category = 'Service Order'
          itemGA4.item_category = 'Service Order'
        }
        item.price = getCalculatedTotalForOrder(order)
        itemGA4.price = parseFloat(getCalculatedTotalForOrder(order))
        item.quantity = '1'
        itemGA4.quantity = 1
        items.push(item)
        itemsGA4.push(itemGA4)
      }
      contractData.items = items
      transactionItemGA4.items = itemsGA4

      transactions.push(contractData)
      transactionsGA4.push(contractDataGA4)
    }
  }

  const gaECommerceObj = {
    transactions,
    transactionsGA4,
  }

  return gaECommerceObj
}

const calculateTotalParksSites = function (contractViewList) {
  const parksInOrder = new Set()
  const sitesInOrder = new Set()

  for (const contractView of contractViewList ?? []) {
    for (const { parkId, webOrder } of contractView?.cartOrderViews ?? []) {
      const siteId = webOrder?.site?.id

      if (parkId) {
        parksInOrder.add(parkId)
      }

      if (siteId) {
        sitesInOrder.add(siteId)
      }
    }
  }

  return {
    parksInOrder,
    sitesInOrder,
  }
}

export const getMixpanelParamsCompletedPayment = function (
  currentCart,
  chubCart,
  offersResults = [],
) {
  const agencyList = process.env.CAMPERHUB_AGENCIES
  const list = agencyList.split(',')
  const props = {}

  if (isEmpty(currentCart)) return {}

  if (currentCart.cartPriceInfo) {
    const cartTotal = getCartTotal(currentCart, 'CART_TOTAL')

    props.totalAmount = cartTotal?.value
  }

  const contractCodes = new Set()
  const contractTypes = new Set()
  const agencyNames = new Set()
  const parkNames = new Set()
  const parkStates = new Set()
  const parkIds = new Set()
  const siteIds = new Set()
  const orderTypes = new Set()
  const bookingTypes = new Set()

  const orderUnits = []
  const arrivalDates = []
  const endDates = []

  for (const contractView of currentCart.contractViewList ?? []) {
    const orderView = contractView?.cartOrderViews.find(
      (webOrder) => webOrder.type !== 'POS_ORDER',
    )

    if (orderView) {
      contractCodes.add(orderView.contractCode)
      contractTypes.add(orderView.contractType)
      agencyNames.add(orderView.agencyName)

      if (orderView.agencyName) {
        if (list.includes(orderView.agencyName.toLowerCase())) {
          bookingTypes.add('AOT')
        } else {
          bookingTypes.add('A1')
        }
      }

      parkNames.add(orderView.parkName)
      parkStates.add(orderView.parkState)
      parkIds.add(orderView.parkId)
      orderTypes.add(orderView.orderType)
      const { webOrder } = orderView

      if (webOrder) {
        siteIds.add(webOrder.site.id)

        orderUnits.push(webOrder.units)
        arrivalDates.push(webOrder.arrivalDate)
        endDates.push(webOrder.endDate)
      }
    }
  }

  props.chubCartID = chubCart?.cartId
  props.currentCartID = currentCart?.cartIdString

  props.contractCode = [...contractCodes].join(',')
  props.contractType = [...contractTypes].join(',')
  props.agencyName = [...agencyNames].join(',')
  props.bookingType = [...bookingTypes].join(',')

  props.parkName = [...parkNames].join(',')
  props.parkState = [...parkStates].join(',')
  props.siteId = [...siteIds].join(',')
  props.parkId = [...parkIds].join(',')
  props.orderType = [...orderTypes].join(',')

  props.orderUnits = orderUnits.join(',')
  props.arrivalDate = arrivalDates.join(',')
  props.endDate = endDates.join(',')

  if (currentCart.contractViewList?.length) {
    const totals = calculateTotalParksSites(currentCart.contractViewList)

    props.totalNumberOfParks = totals.parksInOrder.size

    props.totalNumberOfSites = totals.sitesInOrder.size
  }

  const offers =
    chubCart?.cartItems?.map(
      ({ type, vendor, quoteValue, ref_data: refData }) => ({
        type,
        vendor,
        totalPrice: parseFloat(quoteValue?.totalPrice ?? refData?.total ?? '0'),
      }),
    ) ?? []

  if (!offersResults.some(({ errorCode }) => errorCode)) {
    props.offers = offers
  } else if (offersResults.length === 1) {
    props.offers = []
    props.failedOffers = offers.map((item) => ({
      ...item,
      errorCode: offersResults[0].errorCode,
      errorMesage: offersResults[0].errorMesage,
    }))
  } else if (offersResults.length > 1) {
    const resulsByVendor = {
      sensible_weather: offersResults[0],
      aspira: offersResults[1],
    }
    const isFailedByVendor = {
      sensible_weather: !!offersResults[0]?.errorCode,
      aspira: !!offersResults[1]?.errorCode,
    }

    props.offers = offers.filter(({ vendor }) => !isFailedByVendor[vendor])
    props.failedOffers = offers
      .filter(({ vendor }) => !!isFailedByVendor[vendor])
      .map((item) => {
        const offerResult = resulsByVendor[item.vendor]

        return {
          ...item,
          errorCode: offerResult.errorCode,
          errorMesage: offerResult.errorMesage,
        }
      })
  }
  props.swOffersTotal = props.offers?.reduce((total, offer) => {
    if (offer.vendor === 'sensible_weather') {
      total += offer.totalPrice
    }

    return total
  }, 0)
  props.apexOffersTotal = props.offers?.reduce((total, offer) => {
    if (offer.vendor === 'spira') {
      total += offer.totalPrice
    }

    return total
  }, 0)

  return props
}
