// this is on purpose
// this shims is not working in IE11 when tested in SPRINT 66 branch 31701
// import 'airbnb-js-shims'
// when using airbnb-js-shims the Promise polyfill is not required
import { loadableReady } from '@loadable/component'
import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { Router, match } from 'react-router'
import root from 'window-or-global'

import '../less/themes/ra/main.less'
import { changeTheme, getLocalState } from './actions/Application'
import {
  chubGetApexClubSubscriptionStatus,
  getAdvertiseInitData,
  getAppInitData,
  getCampClubProfileInitData,
  getCart,
  getCheckoutInitData,
  getContactUsInitData,
  getDashboardInitData,
  getDetailedCartProcessResult,
  getEquipmentDetailsInitData,
  getEquipmentInitData,
  getFacilityInitData,
  getFavoritesInitData,
  getFormTestData,
  getInfoInitData,
  getListCampgroundInitData,
  getNotificationsInitData,
  getOffer,
  getPOSItemInitData,
  getPassesInitData,
  getPointsDetailsInitData,
  getPointsInitData,
  getProductAndBookingInitData,
  getProductBookingDetailsInitData,
  getProductBookingSupplementDetailsInitData,
  getProductProductNotificationInitData,
  getProductsDayPassesInitData,
  getProductsHourlySitesInitData,
  getProductsInitData,
  getProfileInitData,
  getRateReservationInitData,
  getReservationCancelInitData,
  getReservationChangeConfirmationInitData,
  getReservationChangeInitData,
  getReservationChangeSupplementDetailsInitData,
  getReservationDetailsInitData,
  getReservationPaymentInitData,
  getReservationPreCheckinInitData,
  getReservationsInitData,
  getVouchersInitData,
  launchDataUpdateSkip,
  setRouteChangeActions,
} from './actions/Backend'
import { getSearchResultsInitData } from './actions/Search'
import { translate } from './components/Translation'
import { isEmpty } from './external/utilities/GeneralUtils'
import { getRoutes } from './raRoutes'
import * as ApplicationReducer from './reducers/Application'
import * as BackendReducer from './reducers/Backend'
import History from './router/history'
import configureStore from './store'
import { initBraze } from './thirdParty/braze'
import { initMixpanel } from './thirdParty/mixpanel'
import {
  getPathForAnalytics,
  initAnalytics,
  logAnalytics,
} from './utilities/Analytics'
import { injectData, isAppInitComplete } from './utilities/AppUtils'
import {
  getGoogleAnalytics4Key,
  getGoogleAnalyticsKey,
} from './utilities/ConfigUtils'
import { getProcessIdFromURLQuery } from './utilities/QueryParamUtils'
import {
  getEmbeddedInitialState,
  getInitialState,
  isDataUpdateRequired,
} from './utilities/ServerUtils'

let appInitComplete = false
let lastPendingAnalytic = null
const store = configureStore(
  getInitialState(
    BackendReducer.initialState,
    ApplicationReducer.initialState,
    getEmbeddedInitialState('initialState'),
  ),
)

const scrollPageUp = function () {
  root.scrollTo(0, 0)
}

const logPageAnalytics = function () {
  if (appInitComplete) {
    logAnalytics()
  } else {
    lastPendingAnalytic = getPathForAnalytics()
  }
}

const initPageAnalytics = function (state) {
  const mainConfig = state.backend.config.main
  const brandConfig = state.backend.config.brand

  let googleAnalyticsKey = ''
  let googleAnalytics4Key = ''
  if (!isEmpty(mainConfig) && !isEmpty(brandConfig)) {
    googleAnalyticsKey = getGoogleAnalyticsKey(
      mainConfig,
      brandConfig.appEnvType,
    )
    googleAnalytics4Key = getGoogleAnalytics4Key(
      mainConfig,
      brandConfig.appEnvType,
    )
    if (!isEmpty(googleAnalyticsKey) || !isEmpty(googleAnalytics4Key)) {
      initAnalytics(googleAnalyticsKey, googleAnalytics4Key)
    }
  }
}

const logPendingPageAnalytics = function () {
  if (lastPendingAnalytic !== null) {
    logAnalytics(lastPendingAnalytic)
  }
}

const handleStateChange = function () {
  if (!appInitComplete) {
    const state = store.getState()
    const currAppInitcomplete = isAppInitComplete(state.backend.pageInits)
    if (currAppInitcomplete) {
      appInitComplete = currAppInitcomplete
      initPageAnalytics(state)
      injectData(state)
      logPendingPageAnalytics()
      unsubscribe()
    }
  }
}

const unsubscribe = store.subscribe(handleStateChange)

const loadHomeInitData = function (transition) {
  logPageAnalytics()
  if (isDataUpdateRequired(store, transition, 2)) {
    store.dispatch(setRouteChangeActions(true))
    // logPageAnalytics()
    scrollPageUp()
  } else {
    store.dispatch(launchDataUpdateSkip())
  }
}

const loadAppInitData = function (transition) {
  if (isDataUpdateRequired(store, transition, 1)) {
    const rootTheme = root.theme
    const rootBrand = root.brand
    const rootTraceId = root.traceId
    const rootInfo = {
      theme: rootTheme,
      brandName: rootBrand,
      traceId: rootTraceId,
    }
    const state = store.getState()
    const locale = state.backend.intl.locale
    store.dispatch(changeTheme(rootTheme))
    store.dispatch(getLocalState())
    store.dispatch(
      getAppInitData(rootInfo, locale, transition.location.query, true),
    )
  } else {
    store.dispatch(launchDataUpdateSkip())
  }
}

const loadFacilityInitData = function (transition) {
  // logPageAnalytics()
  if (isDataUpdateRequired(store, transition, 2)) {
    // logPageAnalytics()
    store.dispatch(
      getFacilityInitData(
        transition.params.contractId,
        transition.params.facilityId,
        transition.location.query,
        true,
      ),
    )
    scrollPageUp()
  } else {
    store.dispatch(launchDataUpdateSkip())
  }
}

const changeFacilityInitData = function (previousState, newState) {
  store.dispatch(
    getFacilityInitData(
      newState.params.contractId,
      newState.params.facilityId,
      newState.location.query,
      true,
    ),
  )
}

const loadProductsInitData = function (transition) {
  // logPageAnalytics()
  if (isDataUpdateRequired(store, transition, 3)) {
    // logPageAnalytics()
    store.dispatch(
      getProductsInitData(
        transition.params.contractId,
        transition.params.facilityId,
        transition.location.query,
        true,
      ),
    )
    scrollPageUp()
  } else {
    store.dispatch(launchDataUpdateSkip())
  }
}

const changeProductsInitData = function (previousState, newState) {
  store.dispatch(
    getProductsInitData(
      newState.params.contractId,
      newState.params.facilityId,
      newState.location.query,
      true,
    ),
  )
}

const loadProductsDayPassesInitData = function (transition) {
  logPageAnalytics()
  if (isDataUpdateRequired(store, transition, 3)) {
    store.dispatch(
      getProductsDayPassesInitData(
        transition.params.contractId,
        transition.params.facilityId,
        transition.location.query,
        true,
      ),
    )
    scrollPageUp()
  } else {
    store.dispatch(launchDataUpdateSkip())
  }
}

const changeProductsDayPassesInitData = function (previousState, newState) {
  store.dispatch(
    getProductsDayPassesInitData(
      newState.params.contractId,
      newState.params.facilityId,
      newState.location.query,
      true,
    ),
  )
}

const loadProductsHourlySitesInitData = function (transition) {
  // logPageAnalytics()
  if (isDataUpdateRequired(store, transition, 3)) {
    // logPageAnalytics()
    store.dispatch(
      getProductsHourlySitesInitData(
        transition.params.contractId,
        transition.params.facilityId,
        transition.location.query,
        true,
      ),
    )
    scrollPageUp()
  } else {
    store.dispatch(launchDataUpdateSkip())
  }
}

const changeProductsHourlySitesInitData = function (previousState, newState) {
  store.dispatch(
    getProductsHourlySitesInitData(
      newState.params.contractId,
      newState.params.facilityId,
      newState.location.query,
      true,
    ),
  )
}

const loadSearchResultsInitData = function (transition) {
  logPageAnalytics()
  if (isDataUpdateRequired(store, transition, 2)) {
    store.dispatch(getSearchResultsInitData(transition.location.query, true))
    scrollPageUp()
  } else {
    store.dispatch(launchDataUpdateSkip())
  }
}

const changeSearchResultsInitData = function (previousState, newState) {
  store.dispatch(getSearchResultsInitData(newState.location.query, true))
}

const setGenericRouteChangeActions = function (transition) {
  if (isDataUpdateRequired(store, transition, 3)) {
    store.dispatch(setRouteChangeActions(true))
    logPageAnalytics()
    scrollPageUp()
  } else {
    store.dispatch(launchDataUpdateSkip())
  }
}

const loadProductAndBookingInitData = function (transition) {
  logPageAnalytics()
  if (isDataUpdateRequired(store, transition, 2)) {
    store.dispatch(
      getProductAndBookingInitData(
        transition.params.contractId,
        transition.params.facilityId,
        transition.params.productId,
        transition.location.query,
        null,
        true,
      ),
    )
    scrollPageUp()
  } else {
    store.dispatch(launchDataUpdateSkip())
  }
}

const loadProductAndBookingInitDataSingleDay = function (transition) {
  logPageAnalytics()
  if (isDataUpdateRequired(store, transition, 2)) {
    store.dispatch(
      getProductAndBookingInitData(
        transition.params.contractId,
        transition.params.facilityId,
        transition.params.productId,
        transition.location.query,
        null,
        true,
        true,
      ),
    )
    scrollPageUp()
  } else {
    store.dispatch(launchDataUpdateSkip())
  }
}

const loadProductNotificationInitData = function (transition) {
  store.dispatch(
    getProductProductNotificationInitData(
      transition.params.contractId,
      transition.params.facilityId,
      transition.params.productId,
      transition.location.query,
      false,
    ),
  )
  logPageAnalytics()
  scrollPageUp()
}

const changeProductAndBookingInitData = function (previousState, newState) {
  store.dispatch(
    getProductAndBookingInitData(
      newState.params.contractId,
      newState.params.facilityId,
      newState.params.productId,
      newState.location.query,
      previousState.location.query,
      false,
    ),
  )
}

const changeProductAndBookingInitDataSingleDay = function (
  previousState,
  newState,
) {
  store.dispatch(
    getProductAndBookingInitData(
      newState.params.contractId,
      newState.params.facilityId,
      newState.params.productId,
      newState.location.query,
      previousState.location.query,
      false,
      true,
    ),
  )
}

const loadProductBookingDetailsInitData = function (transition) {
  store.dispatch(
    getProductBookingDetailsInitData(transition.location.query, true),
  )
  logPageAnalytics()
  scrollPageUp()
}

const loadProductBookingSupplementDetailsInitData = function (transition) {
  store.dispatch(
    getProductBookingSupplementDetailsInitData(transition.location.query, true),
  )
  logPageAnalytics()
  scrollPageUp()
}

const loadFavoritesInitData = function (transition) {
  store.dispatch(getFavoritesInitData(transition.location.query, true))
  logPageAnalytics()
  scrollPageUp()
}

const changeFavoritesInitData = function () {
  // store.dispatch(getFavoritesChangeData(newState.location.query, true))
}

const loadContactUsInitData = function (transition) {
  logPageAnalytics()
  if (isDataUpdateRequired(store, transition, 2)) {
    store.dispatch(getContactUsInitData(transition.location.query, true))
    scrollPageUp()
  } else {
    store.dispatch(launchDataUpdateSkip())
  }
}

const loadAdvertiseInitData = function (transition) {
  logPageAnalytics()
  if (isDataUpdateRequired(store, transition, 2)) {
    store.dispatch(getAdvertiseInitData(transition.location.query, true))
    scrollPageUp()
  } else {
    store.dispatch(launchDataUpdateSkip())
  }
}

const loadInfoInitData = function (transition) {
  logPageAnalytics()
  store.dispatch(
    getInfoInitData(
      transition.params.infoItemId,
      transition.location.query,
      true,
    ),
  )
  scrollPageUp()
}

const loadCartData = function () {
  store.dispatch(getCart(true))
  scrollPageUp()
}

const loadCartCheckoutInitData = async function () {
  await store.dispatch(chubGetApexClubSubscriptionStatus())
  store.dispatch(getCheckoutInitData(true))
  logPageAnalytics()
  scrollPageUp()
}

const loadCartViewInitData = function () {
  store.dispatch(setRouteChangeActions(true))
  logPageAnalytics()
  scrollPageUp()
}

const loadCartConfirmationInitData = function (transition) {
  const processId = getProcessIdFromURLQuery(transition.location.query)

  if (!processId) return

  store.dispatch(getDetailedCartProcessResult(processId, true))

  logPageAnalytics()
  scrollPageUp()
}

const loadOfferInitData = function (transition) {
  store.dispatch(getOffer(transition.location.query, true))
  logPageAnalytics()
  scrollPageUp()
}

const loadPOSItemInitData = function (transition) {
  store.dispatch(getPOSItemInitData(transition.location.query, true))
  logPageAnalytics()
  scrollPageUp()
}

const loadListCampgroundData = function () {
  store.dispatch(getListCampgroundInitData(true))
}

const loadErrorInitData = function () {
  logPageAnalytics()
  scrollPageUp()
}

const loadNotLoggedInInitData = function () {
  logPageAnalytics()
  scrollPageUp()
}

const loadNotFoundInitData = function () {
  logPageAnalytics()
  scrollPageUp()
}

/* My Account */
const loadDashboardInitData = function () {
  store.dispatch(getDashboardInitData(true))
  logPageAnalytics()
  scrollPageUp()
}

const loadReservationsInitData = function (transition) {
  store.dispatch(getReservationsInitData(transition.location.query, true))
  logPageAnalytics()
  scrollPageUp()
}
const changeReservationsInitData = function (previousState, newState) {
  store.dispatch(getReservationsInitData(newState.location.query, false))
}

const loadReservationDetailsInitData = function (transition) {
  store.dispatch(getReservationDetailsInitData(transition.location.query, true))
  logPageAnalytics()
  scrollPageUp()
}

const changeReservationDetailsInitData = function (previousState, newState) {
  store.dispatch(getReservationDetailsInitData(newState.location.query, true))
  logPageAnalytics()
  scrollPageUp()
}

const loadReservationCancelInitData = function (transition) {
  store.dispatch(getReservationCancelInitData(transition.location.query, true))
  logPageAnalytics()
  scrollPageUp()
}

const loadReservationPreCheckinInitData = function (transition) {
  store.dispatch(
    getReservationPreCheckinInitData(transition.location.query, true),
  )
  logPageAnalytics()
  scrollPageUp()
}

const loadReservationPaymentInitData = function (transition) {
  store.dispatch(getReservationPaymentInitData(transition.location.query, true))
  logPageAnalytics()
  scrollPageUp()
}

const loadReservationChangeInitData = function (transition) {
  store.dispatch(getReservationChangeInitData(transition.location.query, true))
  logPageAnalytics()
  scrollPageUp()
}

const loadReservationChangeSupplementDetailsInitData = function (transition) {
  store.dispatch(
    getReservationChangeSupplementDetailsInitData(
      transition.location.query,
      true,
    ),
  )
  logPageAnalytics()
  scrollPageUp()
}

const loadReservationChangeconfirmationInitData = function (transition) {
  store.dispatch(
    getReservationChangeConfirmationInitData(transition.location.query, true),
  )
  logPageAnalytics()
  scrollPageUp()
}

const loadNotificationsInitData = function () {
  store.dispatch(getNotificationsInitData(true))
  logPageAnalytics()
  scrollPageUp()
}

const loadApplicationsInitData = function () {
  // store.dispatch(getApplicationsInitData(true))
  logPageAnalytics()
  scrollPageUp()
}

const loadPassesInitData = function (transition) {
  store.dispatch(getPassesInitData(transition.location.query, true))
  logPageAnalytics()
  scrollPageUp()
}

const changePassesInitData = function (previousState, newState) {
  store.dispatch(getPassesInitData(newState.location.query, false))
}

const loadVouchersInitData = function (transition) {
  store.dispatch(getVouchersInitData(transition.location.query, true))
  logPageAnalytics()
  scrollPageUp()
}

const changeVouchersInitData = function (previousState, newState) {
  store.dispatch(getVouchersInitData(newState.location.query, false))
}

const loadPointsInitData = function () {
  store.dispatch(getPointsInitData(true))
  logPageAnalytics()
  scrollPageUp()
}

const loadPointsDetailsInitData = function (transition) {
  store.dispatch(getPointsDetailsInitData(transition.location.query, true))
  logPageAnalytics()
  scrollPageUp()
}

const changePointsDetailsInitData = function (previousState, newState) {
  store.dispatch(getPointsDetailsInitData(newState.location.query, false))
}

const loadEquipmentInitData = function () {
  store.dispatch(getEquipmentInitData(true))
  logPageAnalytics()
  scrollPageUp()
}

const loadEquipmentDetailsInitData = function (transition) {
  store.dispatch(getEquipmentDetailsInitData(transition.location.query, true))
  logPageAnalytics()
  scrollPageUp()
}

const loadPrintTicketsPermitsInitData = function () {
  // store.dispatch(getPrintTicketsPermitsInitData(true))
  logPageAnalytics()
  scrollPageUp()
}

const loadProfileInitData = function () {
  store.dispatch(getProfileInitData(true))
  logPageAnalytics()
  scrollPageUp()
}

const loadCampClubProfileInitData = function () {
  store.dispatch(getCampClubProfileInitData(true))
  logPageAnalytics()
  scrollPageUp()
}

const loadRateReservationInitData = function (transition) {
  store.dispatch(getRateReservationInitData(transition.location.query, true))
  logPageAnalytics()
  scrollPageUp()
}

const loadFormTestData = function (transition) {
  store.dispatch(getFormTestData(transition.location.query, true))
}

initMixpanel(store.getState())
initBraze(store.getState())

document.documentElement.style.setProperty(
  '--ads-watermark-label',
  `"${translate(store.getState().backend.intl, 'AD_LABEL')}"`,
)

const rootElem = document.querySelector('#container')
const isRootEmpty = !rootElem.innerHTML

const renderMethod = isRootEmpty ? ReactDOM.render : ReactDOM.hydrate

loadableReady(async () => {
  const routes = getRoutes({
    loadAppInitData,
    loadHomeInitData,
    loadFacilityInitData,
    changeFacilityInitData,
    setGenericRouteChangeActions,
    loadProductsDayPassesInitData,
    changeProductsDayPassesInitData,
    loadProductsHourlySitesInitData,
    changeProductsHourlySitesInitData,
    loadProductsInitData,
    changeProductsInitData,
    loadSearchResultsInitData,
    changeSearchResultsInitData,
    loadProductAndBookingInitData,
    changeProductAndBookingInitData,
    loadProductAndBookingInitDataSingleDay,
    changeProductAndBookingInitDataSingleDay,
    loadProductNotificationInitData,
    loadDashboardInitData,
    loadReservationsInitData,
    changeReservationsInitData,
    loadReservationDetailsInitData,
    changeReservationDetailsInitData,
    loadReservationCancelInitData,
    loadReservationPreCheckinInitData,
    loadReservationPaymentInitData,
    loadReservationChangeInitData,
    loadReservationChangeSupplementDetailsInitData,
    loadReservationChangeconfirmationInitData,
    loadNotificationsInitData,
    loadApplicationsInitData,
    loadPassesInitData,
    changePassesInitData,
    loadVouchersInitData,
    changeVouchersInitData,
    loadPointsInitData,
    loadPointsDetailsInitData,
    changePointsDetailsInitData,
    loadEquipmentInitData,
    loadEquipmentDetailsInitData,
    loadPrintTicketsPermitsInitData,
    loadProfileInitData,
    loadCampClubProfileInitData,
    loadRateReservationInitData,
    loadCartData,
    loadProductBookingDetailsInitData,
    loadProductBookingSupplementDetailsInitData,
    loadCartViewInitData,
    loadCartCheckoutInitData,
    loadCartConfirmationInitData,
    loadOfferInitData,
    loadPOSItemInitData,
    loadFavoritesInitData,
    changeFavoritesInitData,
    loadContactUsInitData,
    loadAdvertiseInitData,
    loadInfoInitData,
    loadListCampgroundData,
    loadFormTestData,
    loadErrorInitData,
    loadNotLoggedInInitData,
    loadNotFoundInitData,
  })

  const renderedProps = await new Promise((res, rej) => {
    match({ routes, history: History }, (err, redirect, renderedProps) => {
      if (err) {
        rej(err)
      } else {
        res(renderedProps)
      }
    })
  })

  renderMethod(
    <StrictMode>
      <HelmetProvider>
        <Provider store={store}>
          <Router {...renderedProps}></Router>
        </Provider>
      </HelmetProvider>
    </StrictMode>,
    document.querySelector('#container'),
  )
})
