import PropTypes from 'prop-types'
import React from 'react'

import Cont from '../../../external/components/layout/Cont'
import { AMenuItem } from '../../../external/components/navigation/menu/AMenuItem'
import MenuContainer from '../../../external/components/navigation/menu/MenuContainer'
import SubMenu from '../../../external/components/navigation/menu/SubMenu'
import SubMenuItem from '../../../external/components/navigation/menu/SubMenuItem'
import { isEmpty, isUndefined } from '../../../external/utilities/GeneralUtils'
import {
  forwardToArticles,
  forwardToDestinations,
  forwardToExternalMyProfile,
  forwardToExternalMyReservations,
  forwardToHuntingAndFishing,
  getArticlesRoute,
  getDestinationsRoute,
  getHuntingAndFishingRoute,
  getSignInRoute,
  getSignOutRoute,
} from '../../../router/ExtRouteManager'
import {
  forwardToCartView,
  forwardToExternalCartView,
  forwardToFavorites,
  forwardToLoginWithReturnPath,
  forwardToMyAccountDashboard,
  forwardToMyAccountReservations,
  getCurrentPathFromLocation,
  getFavoritesRoute,
  getMyAccountDashboardRoute,
  getMyAccountReservationsRoute,
  pushRoute,
  sendToExternalPath,
} from '../../../router/RouteManager'
import braze from '../../../thirdParty/braze'
import mixpanel from '../../../thirdParty/mixpanel/index.js'
import { createAndLogEvent } from '../../../utilities/Analytics'
import {
  getSwitchUseNewCheckoutWorkflow,
  getSwitchUseNewMyAccountWorkflow,
  isAllowFavorites,
} from '../../../utilities/ConfigUtils'
import { addDynamicParamsFromStorage } from '../../../utilities/DynamicLinkUtils.js'
import { isLoggedIn } from '../../../utilities/SecurityUtils'
import { simpleTranslate } from '../../Translation'
import CartCountdownTimer from '../timer/CartCountdownTimer'

const MainMenuItems = ({
  intl,
  displayType,
  userProfile,
  brandConfig,
  mainConfig,
  cartTimeToExpiry,
  cartItemCount,
  location,
  closeOnClickOutside,
  onClickWrapper,
  onSignOutRequest,
  menuType,
  onCartCountdownTimerTick,
  homeConfig,
}) => {
  const goToFavorites = function () {
    if (isLoggedIn(userProfile)) {
      forwardToFavorites()
    } else {
      forwardToLoginWithReturnPath(getFavoritesRoute())
    }
  }

  const sendToCartView = function () {
    const useNewCheckoutWorkflow = getSwitchUseNewCheckoutWorkflow(mainConfig)
    if (useNewCheckoutWorkflow === null || !useNewCheckoutWorkflow) {
      forwardToExternalCartView()
    } else {
      forwardToCartView()
    }
  }

  const sendToMyProfile = function () {
    const useNewMyAccountWorkflow = getSwitchUseNewMyAccountWorkflow(mainConfig)
    if (useNewMyAccountWorkflow === null || !useNewMyAccountWorkflow) {
      forwardToExternalMyProfile()
    } else {
      forwardToMyAccountDashboard()
    }
  }

  const sendToMyReservations = function () {
    const useNewMyAccountWorkflow = getSwitchUseNewMyAccountWorkflow(mainConfig)
    if (useNewMyAccountWorkflow === null || !useNewMyAccountWorkflow) {
      forwardToExternalMyReservations()
    } else {
      forwardToMyAccountReservations()
    }
  }

  const localOnClickWrapper = function () {
    if (typeof onClickWrapper !== 'undefined') {
      onClickWrapper()
    }
  }

  const articlesMixpanelEvent = function () {
    mixpanel.mainNavigationArticles()
  }

  const getMenuAddOns = function (isMobile = false) {
    let menuAddOns = null
    if (!isEmpty(homeConfig) && !isUndefined(homeConfig.mainMenuAddOns)) {
      menuAddOns = homeConfig.mainMenuAddOns.items.map((item, i) => {
        if (!item.showItem) {
          return null
        } else if (isMobile && !item.showInMobileMenu) {
          return null
        }

        return (
          <AMenuItem
            key={i}
            imgURL={item.mainImage}
            imgHoverURL={item.hoverImage}
            imgAlt={simpleTranslate(
              intl,
              item.imageDescription.token,
              item.imageDescription.default,
            )}
            imgTitle={simpleTranslate(
              intl,
              item.imageDescription.token,
              item.imageDescription.default,
            )}
            onClick={() => {
              localOnClickWrapper()
              handleBrazeTracking(item)

              const link = addDynamicParamsFromStorage(
                item.link,
                item.linkParams,
              )

              if (!isUndefined(item.analytics) && !isEmpty(item.analytics)) {
                createAndLogEvent(item.analytics)
                if (item.linkInternal) {
                  pushRoute(link)
                } else {
                  sendToExternalPath(link, item.newWindow)
                }
              }
            }}
            label={simpleTranslate(intl, item.label.token, item.label.default)}
            description={simpleTranslate(
              intl,
              item.label.token,
              item.label.default,
            )}
            displayType={displayType}
            closeOnClickOutside={closeOnClickOutside}
          />
        )
      })
    }

    return menuAddOns
  }

  const handleBrazeTracking = (item) => {
    if (item.id === 'apexclub') {
      braze.clickApexClub()
    }
    if (item.id === 'rvShare') {
      braze.clickRVRental()
    }
  }

  const discoverMenuItem = (
    <AMenuItem
      imgURL={'/newra/assets/images/ra/discover.svg'}
      imgHoverURL={'/newra/assets/images/ra/discover-orange.svg'}
      imgAlt={simpleTranslate(intl, 'labels_navigation_discover_img_alt')}
      imgTitle={simpleTranslate(intl, 'labels_navigation_discover_img_title')}
      onClick={() => {}}
      label={simpleTranslate(intl, 'labels_navigation_discover')}
      description={simpleTranslate(intl, 'labels_navigation_discover')}
      displayType={displayType}
      closeOnClickOutside={closeOnClickOutside}
      cmpId={'discover'}
    >
      <SubMenu hasBoxShadow>
        <SubMenuItem
          onClick={() => {
            localOnClickWrapper()
            articlesMixpanelEvent()
            forwardToArticles()
          }}
          to={getArticlesRoute()}
          internal={false}
          label={simpleTranslate(intl, 'labels_navigation_articles')}
        />
        <SubMenuItem
          onClick={() => {
            localOnClickWrapper()
            forwardToDestinations()
          }}
          to={getDestinationsRoute()}
          internal={false}
          label={simpleTranslate(intl, 'labels_navigation_destinations')}
        />
        <SubMenuItem
          onClick={() => {
            localOnClickWrapper()
            forwardToHuntingAndFishing()
          }}
          to={getHuntingAndFishingRoute()}
          internal={false}
          label={simpleTranslate(intl, 'labels_navigation_hunt')}
        />
      </SubMenu>
    </AMenuItem>
  )

  let cartMenuItem = null
  let cartLabel = null
  let cartDescription = null
  let numCartItems = 0
  let cartClassName = ''
  if (cartItemCount > 0) {
    cartLabel = (
      <CartCountdownTimer
        intl={intl}
        cartItemCount={cartItemCount}
        cartTimeToExpiry={cartTimeToExpiry}
        mainConfig={mainConfig}
        onTickCallBack={onCartCountdownTimerTick}
      />
    )
    numCartItems = cartItemCount
    cartClassName = 'main-menu-items__cart-timer'
    cartDescription = (
      <div>
        {`${simpleTranslate(intl, 'labels_navigation_cart')} - `}
        {cartLabel}
      </div>
    )
  } else {
    cartLabel = simpleTranslate(intl, 'labels_navigation_cart')
    cartDescription = cartLabel
  }

  if (!isEmpty(userProfile) && !isEmpty(userProfile.cart)) {
    cartMenuItem = (
      <div className={cartClassName}>
        <AMenuItem
          imgURL={'/newra/assets/images/ra/shopping-cart.svg'}
          imgHoverURL={'/newra/assets/images/ra/shopping-cart-orange.svg'}
          imgAlt={simpleTranslate(intl, 'labels_navigation_cart_img_alt')}
          imgTitle={simpleTranslate(intl, 'labels_navigation_cart_img_title')}
          onClick={() => {
            localOnClickWrapper()
            sendToCartView()
          }}
          label={cartLabel}
          description={cartDescription}
          displayType={displayType}
          iconBubbleNumber={numCartItems}
          closeOnClickOutside={closeOnClickOutside}
        />
      </div>
    )
  }

  let profileMenuItem = null
  let numberOfReminders = 0
  if (
    !isEmpty(userProfile.notification) &&
    parseInt(userProfile.notification, 10) > 0
  ) {
    // Uncomment and remove the line bellow to turn back notification
    // numberOfReminders = parseInt(userProfile.notification, 10)
    numberOfReminders = 0
  }

  if (!isEmpty(userProfile) && !isEmpty(userProfile.firstName)) {
    profileMenuItem = (
      <AMenuItem
        imgURL={'/newra/assets/images/ra/profile.svg'}
        imgHoverURL={'/newra/assets/images/ra/profile-orange.svg'}
        imgAlt={simpleTranslate(intl, 'labels_navigation_profile_img_alt')}
        imgTitle={simpleTranslate(
          intl,
          'labels_navigation_profile_signed_in_img_title',
        )}
        onClick={() => {}}
        label={userProfile.firstName}
        description={simpleTranslate(intl, 'labels_navigation_profile')}
        displayType={displayType}
        iconBubbleNumber={numberOfReminders}
        closeOnClickOutside={closeOnClickOutside}
        cmpId={'profile'}
      >
        <div className={'profile-menu-item'}>
          <SubMenu textAlign={'right'} hasBoxShadow>
            <SubMenuItem
              to={getMyAccountDashboardRoute()}
              onClick={() => {
                localOnClickWrapper()
                sendToMyProfile()
              }}
              label={simpleTranslate(intl, 'labels_navigation_edit_profile')}
            />
            <SubMenuItem
              to={getMyAccountReservationsRoute()}
              onClick={() => {
                localOnClickWrapper()
                sendToMyReservations()
              }}
              label={simpleTranslate(intl, 'labels_navigation_my_reservations')}
            />
            {isAllowFavorites(mainConfig, brandConfig) && (
              <SubMenuItem
                onClick={() => {
                  localOnClickWrapper()
                  goToFavorites()
                }}
                to={getFavoritesRoute()}
                internal={false}
                label={simpleTranslate(intl, 'labels_navigation_favorites')}
              />
            )}
            <SubMenuItem
              to={getSignOutRoute()}
              onClick={() => {
                localOnClickWrapper()
                onSignOutRequest()
              }}
              label={simpleTranslate(intl, 'labels_navigation_sign_out')}
            />
          </SubMenu>
        </div>
      </AMenuItem>
    )
  } else {
    profileMenuItem = (
      <AMenuItem
        linkURL={getSignInRoute()}
        imgURL={'/newra/assets/images/ra/profile.svg'}
        imgHoverURL={'/newra/assets/images/ra/profile-orange.svg'}
        imgAlt={simpleTranslate(intl, 'labels_navigation_profile_img_alt')}
        imgTitle={simpleTranslate(intl, 'labels_navigation_profile_img_title')}
        onClick={() => {
          localOnClickWrapper()
          mixpanel.clickSignIn()
          forwardToLoginWithReturnPath(getCurrentPathFromLocation(location))
        }}
        label={simpleTranslate(intl, 'labels_navigation_sign_in')}
        description={simpleTranslate(intl, 'labels_navigation_sign_in')}
        displayType={displayType}
        closeOnClickOutside={closeOnClickOutside}
      />
    )
  }

  let menuItems = null
  if (menuType === 'burger') {
    menuItems = (
      <div className="main-menu-items__burger">
        {discoverMenuItem}
        {cartMenuItem}
        {profileMenuItem}
        {getMenuAddOns(true)}
      </div>
    )
  } else if (menuType === 'desktop') {
    menuItems = (
      <div className="main-menu-items__desktop">
        {profileMenuItem}
        {cartMenuItem}
        <Cont hideXS>{discoverMenuItem}</Cont>
        {getMenuAddOns(false)}
      </div>
    )
  }

  return (
    <MenuContainer className={'main-menu-items'}>{menuItems}</MenuContainer>
  )
}

MainMenuItems.propTypes = {
  intl: PropTypes.object.isRequired,
  theme: PropTypes.string,
  displayType: PropTypes.string,
  userProfile: PropTypes.object.isRequired,
  brandConfig: PropTypes.object.isRequired,
  mainConfig: PropTypes.object.isRequired,
  cartTimeToExpiry: PropTypes.object.isRequired,
  cartItemCount: PropTypes.number.isRequired,
  location: PropTypes.object,
  closeOnClickOutside: PropTypes.bool,
  onClickWrapper: PropTypes.func,
  onSignOutRequest: PropTypes.func.isRequired,
  menuType: PropTypes.oneOf(['burger', 'desktop']),
  onCartCountdownTimerTick: PropTypes.func,
  homeConfig: PropTypes.object.isRequired,
}

export default MainMenuItems
